* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  font-family: "AnebaNeue";
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin-bottom: 0;
  /* font-family: 'Work Sans'; */

  font-family: "AnebaNeue";

  font-style: normal;
}

body,
html {
  /* font-family: "Work Sans"; */
  font-family: "AnebaNeue";
}

body {
  margin: 0;
  padding: 0;
  /* font-family: "Work Sans"; */
  scroll-behavior: smooth;
  font-family: "AnebaNeue";

  border: none;
}

input:focus {
  outline: none;
}

.login-form {
  padding: 47px 0% 47px 0%;
  background: linear-gradient(
    120.09deg,
    rgba(165, 173, 176, 0.5) 7.14%,
    rgba(98, 105, 123, 0.5) 45.56%,
    rgba(15, 16, 31, 0.5) 81.66%
  ) !important;
  box-shadow: 0px 11px 19px -1px rgba(0, 0, 0, 0.24) !important;
  border-radius: 16px !important;
  width: 29%;
}

.login-logo {
  width: 63px;
  margin: auto;
}

.login-logo img {
  width: 100%;
}

.welcome-bk {
  font-family: "AnebaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  margin-top: 2%;
  margin-bottom: 2% !important;
  text-align: left !important;
  line-height: 156.8%;
  color: #ffffff;
}

.login-field {
  width: 87%;
  margin: auto;
}

.login-form-control {
  background-color: black !important;
  border: 2px solid #343d49 !important;
  box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.16) !important;
  border-radius: 12px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  height: 64px !important;
  font-weight: 500 !important;
  font-family: "AnebaNeue";
  font-style: normal;

  color: #cbd8e7 !important;
}

.login-label {
  font-weight: 600 !important;
  font-family: "AnebaNeue";
  font-style: normal;
  line-height: 9px !important;
  top: 7% !important;
  margin-bottom: 10px;
  /* padding-left: 9px; */
  color: #ffffff !important;
}

.signin-btn {
  width: 100%;
  background: radial-gradient(
    100% 100% at 50% 0%,
    #ffd951 6.77%,
    #cb4848 99.48%
  );
  /* background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #fff6e0 0%,
    #8eef9e 14.58%,
    #006088 100%
  ); */
  box-shadow: 0px 6px 10px 1px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  height: 64px;
  font-size: 24px;
  line-height: 137.3%;
  text-align: center;
  color: #570f0b;
  font-size: bold;
  font-weight: 600;
  margin-top: 36px;
  font-family: "AnebaNeue";
}

.signin-btn:hover {
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #8a7f94 0%,
    #4c444e 41.67%,
    #241e35 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 15px 13px 1px rgba(0, 0, 0, 0.32);
  color: white !important;
}

.signin-des {
  font-size: 16px;
  line-height: 19px;
  color: #aaaaaa;
  margin-top: 5%;
  float: left;
  font-family: "AnebaNeue";
}

.text-seagreen {
  text-decoration: underline;
  /* color: #01a4b7 !important; */
  color: #e9a14b !important;
  margin-left: 1%;
  cursor: pointer;
  font-family: "AnebaNeue";
}

.login-body {
  min-height: 100vh;
  max-height: 180vh;
  padding: 5% 0%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap-login-body {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 105px; */
}

.showeye {
  position: absolute;
  right: 8px;
  top: 20px;
  cursor: pointer;
  width: 2vw;
}

.login-bottom {
  display: flex;
  width: 94%;
  margin: auto;
  justify-content: space-between;
  margin-top: 4%;
}

.login-bottom h1 {
  font-family: "AnebaNeue";
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #aaaaaa;
  cursor: pointer;
  font-weight: 500;
}

.container-2 {
  display: block;
  position: relative;
  padding-left: 6%;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "AnebaNeue";
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #aaaaaa;
}

/* Hide the browser's default radio button */
.container-2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #aaaaaa;
  border-radius: 4px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-2 input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-2 .checkmark:after {
  top: 2px;
  left: 2px;
  width: 8px;
  height: 8px;
  /* background: #01a4b7 !important; */
  background: #e9a14b !important;
  border-radius: 2px;
}

/* =====================> New CSS is Here <============================= */

:root {
  --litgray: #cbd8e7;
  --yellow: #e9a14b;
  --white: #fff;
  --darkblue: #160e24;
  --blue: #525197;
}

.sign_in_desc {
  color: #cbd8e7;
  font-size: 14px;
  padding-bottom: 5%;
  font-weight: 500;
}

/* Cart Page CSS  */
.cart_main_div {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100dvh;
  max-height: 180vh;
  background-size: cover;
  background-position: bottom;
  background-image: url("../assets/images/loginpage.png");
  background-position: 50%;
  background-repeat: no-repeat !important;
  background-color: #000000 !important;
}

.new_cart_bg {
  background-image: url("../assets/images/dark-updated-bg.png") !important;
}

/* .cart_main_div::before {
  content: "";
  width: 100%;
  height: 100%;
  min-height: 100dvh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: url("../assets/images/loginpage.png");
  background-position: center;
  background-size: cover;
  mix-blend-mode: luminosity;
} */

.cart-body {
  padding: 5% 2%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap-cart-body {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  min-height: 100dvh;
}

/* 
.cart-body {
  padding: 10% 5%;
  min-height: 100vh;
  max-height: 180vh;
  background-size: cover;
  background-position: center;
  background: linear-gradient(
    133.07deg,
    #010103 0%,
    #272a49 17.02%,
    #063f5b 38.8%,
    #323159 61.08%,
    #1f384a 86.41%,
    #141629 96.13%
  );
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap-cart-body {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.cart-list-main {
  /* background: linear-gradient(
    120.09deg,
    rgba(49, 52, 66, 0.65) 7.14%,
    rgba(18, 73, 90, 0.65) 45.56%,
    rgba(44, 45, 75, 0.65) 81.66%
  );
  box-shadow: 0px 11px 19px -1px rgba(0, 0, 0, 0.24); */
  background: linear-gradient(120.09deg, #4b5360cc 7.14%, #000c 81.66%);
  box-shadow: 0 11px 19px -1px #363e493d;
  opacity: 0.9;
  border-radius: 16px;

  padding: 5%;
}

.cart_cart_detail_box {
  display: flex;
  justify-content: space-between !important;
  flex-direction: column;
}

.cart-list {
  width: 100% !important;
  /* display: flex;
 align-items: center;
 justify-content: center; */
}

/*================= General Css For All Components ================== */
.desktop_none {
  display: none;
}

.mb_1 {
  margin-bottom: 2%;
}

.text-center {
  text-align: center !important;
}

.text-white {
  color: white !important;
}

.f48 {
  font-weight: 600;
  font-family: "AnebaNeue";
  font-style: normal;
  font-size: 48px;
  line-height: 105.8%;
  color: #ffffff;
}

.f24 {
  font-weight: 600;
  font-style: normal;
  font-family: "AnebaNeue";
  font-size: 24px;
  line-height: 119.3%;
  color: var(--litgray);
}

.f16 {
  font-weight: 500;
  font-style: normal;
  font-family: "AnebaNeue";
  font-size: 16px;
  line-height: 119.3%;
  color: #ffffff;
}

.f20 {
  font-weight: 500;
  font-style: normal;
  font-family: "AnebaNeue";
  font-size: 20px;
  line-height: 119.3% !important;
  color: var(--litgray);
  padding-bottom: 5px !important;
}

.f32 {
  font-style: normal;
  font-weight: 500;
  font-family: "AnebaNeue";
  font-size: 32px;
  line-height: 132.3%;
  color: var(--litgray) !important;
}

.f36 {
  font-weight: 600;
  font-size: 36px;
  line-height: 120.6%;
  color: #ffffff;
}

.f64 {
  font-style: normal;
  font-weight: bold;
  font-family: "AnebaNeue";
  font-size: 64px;
  line-height: 105.8%;
  color: #ffffff;
}

.f14 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 118.3%;
  color: #cbd8e7;
  padding-bottom: 8px !important;
}

.headline-cart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cart-headline-prod {
  font-weight: 500 !important;
  margin-left: 10px;
}

.cart-headline-qty {
  margin-right: 40px;
}

.cart-card {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  border: 2px solid #e9a14b;
  border-radius: 16px;
  padding: 4px;
  width: 100%;
}
/* #E3894C */

.cart-card-detail {
  display: flex;
  align-items: center;
}

.cart_card_box {
  padding-right: 20px;
}

.cart_card_img {
  width: 88px;
  height: 88px;
  border-radius: 5px !important;
}

.cart-card-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-card-price-span {
  width: auto !important;
  height: 40px;
  border: 2px solid #162232;
  border-radius: 8px;
  padding: 0px 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coinbox {
  width: auto !important;
  height: 40px;
  padding-left: 35px;
  background: #2e363c;
  border: 2px solid #373f46;
  border-radius: 8px;
  position: relative;
  display: grid;
  font-weight: 600;
  line-height: 129.8%;
  text-align: right;
  color: #5f657a;
  align-items: center;
}

.coinbox p {
  margin-bottom: 0px;
  font-size: 14px;
  margin-right: 7px;
}

.coinbox img {
  position: absolute;
  top: -2px;
  left: -4px;
  width: 45px;
}

.cart-card-qty-box {
  color: white;
  padding-top: 15px;
}

.cart-qty-btn {
  background: #0d0d0d !important;
  border: none !important;
  color: #5f607a !important;
  font-weight: 600 !important;
  height: 60px;
}

.cart-card-qty-show {
  background-color: #1a1a1a !important;
  border: none !important;
  font-weight: 500 !important;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
}

.cart_card_remove_box {
  display: flex;
  align-items: center;
  justify-content: center;
  color: "#5F657A" !important;
  cursor: pointer;
  padding-top: 10px;
}

.remove_text {
  /* padding-top: 15px; */
  font-weight: 600;
}

.cart_list_total-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.mobile_footer_space {
  padding: 100px 0px !important;
}

.empty_space {
  padding: 50px 0px !important;
}

.header_login_btn {
  /* background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #fff6e0 0%,
    #8eef9e 14.58%,
    #006088 100%
  ) !important;
  box-shadow: 0px 6px 10px 1px rgba(0, 0, 0, 0.32) !important; */

  background: radial-gradient(
    100% 100% at 50% 0%,
    #ffd951 6.77%,
    #cb4848 99.48%
  );
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  color: #000000;
  border-radius: 8px !important;
}

.header_login_btn:hover {
  /* background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #8a7f94 0%,
    #4c444e 41.67%,
    #241e35 100%
  ) !important; */
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #8a7f94 0%,
    #4c444e 41.67%,
    #241e35 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 15px 13px 1px rgba(0, 0, 0, 0.32) !important;
  color: white !important;
}
/* jumio button sttaus  */
.desk_status{
  background: radial-gradient(
    100% 100% at 50% 0%,
    #ffd951 6.77%,
    #cb4848 99.48%
  );
}
.Retry{
  background-color: red !important;
}
.Pending{
  background-color: yellow !important;
}

.jumio_status {
  display: flex;
  width: 96px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  height: 40px;
  border-radius: 8px;
  background: radial-gradient(
    100% 100% at 50% 0%,
    #ffd951 6.77%,
    #cb4848 99.48%
  );
 
  color: #570f0b;
  text-align: center;
  font-family: "AnebaNeue";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 168.8%; /* 27.008px */
}
.css-13cymwt-control {
  background: transparent !important;
  border: none !important;
}

.state__input-container .css-qbdosj-Input {
  color: white !important;
}

.state__control--is-focused {
  background: transparent !important;
  border: none !important;
}

#react-select-3-input {
  color: white !important;
}
.loader-main-wrapper {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes flipY {
  0%,
  100% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
}

.loader-main-wrapper img {
  width: 80px;
  height: 110px;
  transform-style: preserve-3d !important;
  animation: flipY 1s infinite reverse !important;
}

/* Stripe form loader */
.stripe-form-loader{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20vh;
}

#payment-message{
  color: red;
}
.span-w{width: 102px;}

.bonus-text{
  font-weight: 500;
    font-style: normal;
    font-family: "Aneba Neue";
    font-size: 16px;
    color: var(--litgray);
    margin-bottom: 9px !important;
}
