/*  fonts  */
.f-20-SemiBold {
  font-family: "AnebaNeue";
  font-weight: 600;
  font-size: 20px;
  line-height: 127.1%;
  color: #cbd8e7;
}

.f-14-medium {
  font-family: "AnebaNeue";
  font-weight: 500;
  font-size: 14px;
  line-height: 127.1%;
  color: #cbd8e7;
}

/*  margins */
.m-btm-40 {
  margin-bottom: 40px;
}

/* paddings */

/* ======================= signup header ====================== */
.signup_header_conatiner {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: max-content;
  padding-top: 30px;
}

.signup_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.signup_header .signup_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 88px;
  /* background: #161b30; */
  border-radius: 16px;
  cursor: pointer;
}

.signup_header .signup_logo img {
  width: 55px;
  height: 75px;
}

.signup_header .signup_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 88px;
  /* background: #161b30; */
  border-radius: 16px;
}

.signup_header .signup_btn .signup_btn_button {
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #8a7f94 0%,
    #4c444e 41.67%,
    #241e35 100%
  ); */
  background: radial-gradient(
    100% 100% at 50% 0%,
    #ffd951 6.77%,
    #cb4848 99.48%
  );
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  color: #570f0b !important;
  font-family: "AnebaNeue";
  font-weight: 600;
  font-size: 16px;
  line-height: 138.8%;
  text-align: center;
  border-radius: 8px;
}

.signup_header .signup_btn .signup_btn_button:hover {
  /* background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #fff6e0 0%,
    #8eef9e 14.58%,
    #006088 100%
  ); */
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #8a7f94 0%,
    #4c444e 41.67%,
    #241e35 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 15px 13px 1px rgba(0, 0, 0, 0.32) !important;
  color: white !important;
}

/* ============================= header wrapper of main header ===========================================*/

.theme-container-padd-100 {
  padding-top: 50px !important;
}

/* ============================ satelite abouts us page ====================================*/
/* satellite_blend_bg */

.satellite_blend {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100dvh;
  background-image: url("../assets/images/dark-updated-bg.png");
  /* background: linear-gradient(
    133.07deg,
    #010103 0%,
    #272a49 17.02%,
    #063f5b 38.8%,
    #323159 61.08%,
    #1f384a 86.41%,
    #141629 96.13%
  ); */
}

.satellite_blend::before {
  /* Apply blend mode to this */
  content: "";
  width: 100%;
  height: 100%;
  min-height: 100dvh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: url("../assets/images/dark-updated-bg.png");
  background-position: center;
  background-size: cover;
  /* mix-blend-mode: luminosity; */
}

.satellite_blend .satellite_inner {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  min-height: 100dvh;
}

.top_light_wrapper {
  /* background: url("../assets/images/top-light.png"); */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 400px;
  z-index: -2;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(12, 14, 27, 0) 0%,
    rgba(6, 7, 16, 0.91) 100%
  );
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.bottom_light_wrapper {
  /* background: url("../assets/images/bottom-light.png"); */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -2;
  height: 400px;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(12, 14, 27, 0) 0%,
    rgba(6, 7, 16, 0.84) 100%
  );
}

.elev_beats_wrapper {
  width: 376px;
}

.about_us_contact_wrapp {
  padding: 50px 0px;
}

.about_us_commitment_wrapp {
  padding: 50px 0px;
}

.f-32 {
  font-family: "AnebaNeue";
  font-weight: 500;
  font-size: 32px;
  line-height: 116.8%;
  color: #ffffff;
}

.f-16 {
  font-family: "AnebaNeue";
  font-weight: 500;
  font-size: 16px;
  line-height: 119.3%;
  /* or 19px */
  color: #cbd8e7;
}

.contact_wrapp_left {
  width: 296px;
}

.contact_h1_aboutUs_satl {
  font-family: "AnebaNeue";
  font-weight: 500;
  font-size: 32px;
  line-height: 116.8%;
  /* or 37px */
  color: #ffffff;
  margin-bottom: 0px;
}

.img_blocks_wrap {
  height: 456px;
  width: 448px;
}

.img_blocks_wrap img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

/* section one about us */
.contact_p_aboutUs_satl {
  cursor: pointer;
  font-family: "AnebaNeue";
  font-weight: 500;
  font-size: 16px;
  line-height: 147.8% !important;
  /* identical to box height, or 24px */
  /* color: #01a4b7 !important; */
  color: #e9a14b !important;
  margin: 16px 0px 32px 0px !important;
}

.contact_span_aboutUs_satl_p {
  font-family: "AnebaNeue";
  font: 500;
  font-style: italic;
  font-size: 24px;
  line-height: 116.8%;
  /* or 28px */
  margin-bottom: 0px;
  color: #ffffff;
}

.contact_p_team {
  font-family: "AnebaNeue";
  font-weight: 500;
  font-size: 16px;
  line-height: 147.8%;
  /* identical to box height, or 24px */
  color: #ffffff;
  margin-top: 16px;
  margin-bottom: 0px;
}

.elev_beats_h1 {
  font-family: "AnebaNeue";
  font-weight: 600;
  font-size: 48px;
  line-height: 116.8%;
  color: #ffffff;
  margin-bottom: 40px !important;
}

.elev_beats_p {
  font-family: "AnebaNeue";
  font-weight: 500;
  font-size: 16px;
  line-height: 119.3%;
  /* or 19px */

  color: #cbd8e7;
}

.about_us_commitment_left {
  height: 456px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.elev_beats_padding_right {
  padding-right: 0px !important;
}

.contact_wrapp_mobile {
  display: none;
}

.elev_beats_padding_right {
  padding-left: 30px !important;
}

.img_blocks_wrap {
  margin-left: 50px;
}

.about_us_cus_title_text {
  margin-top: 5px;
}

.about_us_cus_title_text-desk {
  display: block;
}

.about_us_cus_title_text-mob {
  display: none;
}

/* ================================== Payment Success =================================== */
.payment_screeen_wrapper {
  height: 100%;
  width: 100%;
  min-height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.close_btn {
  position: absolute;
  top: 20px;
  right: 10%;
  width: 24px;
  height: 24px;
  display: none;
  cursor: pointer;
}

.close_btn img {
  width: 100%;
  height: 100%;
}

.payment_screeen {
  width: 752px;
  height: 816px;
  border-radius: 16px;
  background: linear-gradient(120.09deg, #4b5360cc 7.14%, #000c 81.66%);
  box-shadow: 0 11px 19px -1px #363e493d;
  opacity: 0.9;
  margin: 50px 0px;
}

.payment_screeen .success_logo {
  height: 72px;
  width: 100%;
  margin-top: 64px;
  display: flex;
  justify-content: center;
}

.payment_screeen .success_logo img {
  height: 72px;
  width: 72px;
}

.payment_screeen .success_tittle {
  height: 56px;
  width: 100%;
  text-align: center;
}

.payment_screeen .success_tittle h1 {
  margin-bottom: 0px;
  margin-top: 24px;
  font-family: "AnebaNeue";
  font-weight: 600;
  font-size: 48px;
  line-height: 105.8%;
  text-align: center;
  color: #ffffff;
}

.payment_screeen .success_para {
  width: 100%;
  margin-top: 16px;
}

.payment_screeen .success_para p {
  width: 90%;
  font-family: "AnebaNeue";
  font-weight: 500;
  font-size: 24px;
  line-height: 136.3%;
  text-align: center;
  color: #cbd8e7;
  margin: auto;
}

.center-img {
  width: 272px;
  height: 272px;
  border-radius: 16px;
  overflow: hidden;
  margin-top: 64px;
  margin: 64px auto 54px;
  border-radius: 16px;
}

.center-img img {
  width: 100%;
  height: 100%;
}

.purchasing-btns {
  min-height: 64px;
  width: 622px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.purchasing-btns .spa-between {
  display: flex;
  align-items: center;
  justify-content: center;
}

.purchasing-btns .play-game-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 304px;
  height: 64px;
  font-family: "AnebaNeue";
  font-weight: 600;
  font-size: 24px;
  line-height: 138.8%;
  text-align: center;
  color: #ffffff;
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #bbb3be 0%,
    #575859 36.98%,
    #2f2f30 68.75%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 15px 13px 1px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
}

.purchasing-btns .download-gme-btn {
  width: 304px;
  height: 64px;
  font-family: "AnebaNeue";
  font-weight: 600;
  font-size: 24px;
  line-height: 138.8%;
  text-align: center;
  color: white;
  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb4848 99.48%);
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
}

/* single_product_wrapper */
.single_product_wrapper {
  width: 100%;
  height: max-content;
  min-height: 700px !important;
}

.single_product_wrapper .product_details {
  width: 100%;
  display: flex;
}

.product_details .product_img {
  height: 308px;
  flex-basis: 26%;
}

.product_details .product_img img {
  width: 100%;
  height: 100%;
}

.product_details .product_detail {
  width: 44.5%;
  max-width: 520px;
  margin: 0px 40px 0px 30px;
}

.product_details .product_detail .p-title {
  font-family: "AnebaNeue";
  font-weight: 500;
  font-size: 24px;
  line-height: 134.3%;
  width: 100%;
  height: auto;
  word-wrap: break-word;
  color: #ffffff;
  margin-bottom: 16px !important;
}

.product_details .product_detail .p-details {
  font-family: "AnebaNeue";
  font-weight: 500;
  height: max-content;
  font-size: 20px;
  word-wrap: break-word;
  line-height: 136.3%;
  color: #cbd8e7;
  min-height: 162px;
}

.product_details .licensing_details {
  flex-basis: 30%;
}

.licensing_details .p-title {
  font-family: "AnebaNeue";
  font-weight: 500;
  font-size: 24px;
  line-height: 134.3%;
  color: #ffffff;
  margin-bottom: 32px !important;
}
/* 
.licensing_details .mp3_lease {
  height: 72px;
  width: 100%;
  border: 2px solid #006088;
  border-radius: 8px;
  margin-bottom: 10px;
}

.mp3_lease .mp3_lease_d-flex-spa-btw {
  width: 100%;
  height: 100%;
}

.mp3_lease .mp3_lease_d-flex-spa-btw .title {
  width: 65%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;

}

.mp3_lease .mp3_lease_d-flex-spa-btw .title .title_details {
  height: 48px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

}

.mp3_lease .mp3_lease_d-flex-spa-btw .price {
  width: 35%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: end;

}

.mp3_lease_d-flex-spa-btw .price .price_box {
  height: 40px;
  min-width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  border: 2px solid #006088;
  border-radius: 8px;
}

.mp3_lease_d-flex-spa-btw .price .price_box p {
  font-family: 'AnebaNeue';
  font-weight: 600;
  font-size: 16px;
  padding: 0px 5px;

  line-height: 175.6%;
  text-align: center;
  color: #CBD8E7;
} */

.cart_btn_wrapper_sp {
  margin-top: 32px;
  width: 344px;
}

.cart_btn_wrapper_sp button {
  height: 64px;
  width: 100%;
  font-family: "AnebaNeue";
  border-radius: 8px;
  font-weight: 600;
  font-size: 24px;
  line-height: 140.8%;
  text-align: center;
  border-radius: 8px;
  /* background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #fff6e0 0%,
    #8eef9e 14.58%,
    #006088 100%
    );
    color: #16203a;
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32); */
  background: radial-gradient(
    100% 100% at 50% 0%,
    #ffd951 6.77%,
    #cb4848 99.48%
  );
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  color: #570f0b;
}

.cart_btn_wrapper_sp button:hover {
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #bbb3be 0%,
    #575859 36.98%,
    #2f2f30 68.75%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  color: #fff !important;
}

.product_details .product_detail .p-details-con-mob {
  display: none;
}
.product_details .product_detailt .p-details-con-mob.clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.product_details .product_detailt .p-details-con-mob.collapsed {
  -webkit-line-clamp: 2; /* Reset line clamp when collapsed */
}

.product_details .product_detailt .p-details-con-mob.expanded {
  -webkit-line-clamp: unset;
}

/* my purchase  */
.purchase_card_wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(204px, 1fr));
  grid-gap: 12px;
}

.purchase_card_wrapper .purchase_card_inner {
  /* width: 224px; */
  margin-bottom: 20px;
  position: relative;
}

.pageTittle_h1 {
  margin: 10px 0px 40px;
}

.pageTittle_h1 h1 {
  font-size: 48px;
  line-height: 114.3%;
  font-family: "AnebaNeue";
  font-weight: 600;
  color: #ffffff;
}

.purchase_card_inner .purchaseCard_img {
  /* height: 224px; */
  cursor: pointer;
  border-radius: 6px;
  overflow: hidden;
}
.opac_imag {
  opacity: 0.3;
}

.purchase_card_inner .purchaseCard_img img {
  height: 250px;
  width: 100%;
}

.purchase_card_inner .purchase_title {
  margin-top: 20px;
}

.purchase_card_inner .purchase_title p {
  color: #cbd8e7;
  font-weight: 600;
  font-size: 16px;
}

.purchase_card_inner .purchase_info {
  margin: 10px 0px 20px;
  width: 100%;
}

.purchase_card_inner .purchase_info p {
  color: #aaaaaa;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Limit to 2 lines of text */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 42px;
}

.download_purchase_btn_wrapper .download-purchase-btn {
  background: radial-gradient(
    100% 100% at 50% 0%,
    #ffd951 6.77%,
    #cb4848 99.48%
  );
  border-radius: 8px;
  height: 64px;
  width: 100%;
  font-size: 24px;
  font-weight: 600;
  color: #570f0b !important;
  line-height: 140.8%;
}

.download_purchase_btn_wrapper .download-purchase-btn:hover {
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #8a7f94 0%,
    #4c444e 41.67%,
    #241e35 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 15px 13px 1px rgba(0, 0, 0, 0.32);
  color: white !important;
}

.download_btn_wrapper_products {
  width: 100%;
}

.download_btn_wrapper_products .product_price {
  width: 50%;
  float: left;
}

.download_btn_wrapper_products .product_price p {
  text-align: center;
  display: inline-block;
  max-width: 100%;
  text-overflow: clip;
  overflow: hidden;
  height: 40px;
  min-width: 60px;
  padding: 4px;
  border-radius: 8px;
  border: 2px solid #e9a14b;
  /* background: linear-gradient(#2b2b2b, #2b2b2b) padding-box,
    radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb4848 99.48%)
      border-box;
  border: 2px solid transparent; */
  color: #cbd8e7;
  font-size: 16px;
  line-height: 175.6%;
  font-family: "AnebaNeue";
  font-weight: 600;
}

.download_btn_wrapper_products .product_buy {
  width: 100%;
  /* float: left; */
}

.download_btn_wrapper_products .product_buy button {
  /* background-color: #004663; */
  background: radial-gradient(
    100% 100% at 50% 0%,
    #ffd951 6.77%,
    #cb4848 99.48%
  );
  color: #570f0b !important;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  color: #cbd8e7;
  font-size: 16px;
  line-height: 168.8%;
  font-weight: 600;
  font-family: "AnebaNeue";
}

.cards_min_height_wrapper {
  width: 100%;
  min-height: 900px;
}

/*  */
.listening_items_wrapper {
  width: 100%;
  padding: 14px 0px;

  border-radius: 8px;
  margin-bottom: 10px;
  background-color: transparent;
}
.productborder2 {
  /* border: 2px solid #01a4b7; */
  border: 2px solid #e9a14b;
}
.productborder {
  border: 2px solid #ffd951;
}
.listening_items_wrapper .listening_items_wrapper_inner {
  display: flex;
  width: 90%;
  margin: auto;
  align-items: center;
}
.listening_items_pack_name {
  width: 70%;
  text-align: left;
  word-wrap: break-word;
}
.listening_items_pack_price {
  width: 30%;
}
.listening_items_pack_price_inner {
  padding: 3px 8px;

  width: max-content;
  border-radius: 8px;
  border: 2px solid #ffd951;
  float: right;
  color: #cbd8e7;
  text-align: center;
  font-family: "AnebaNeue";
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  line-height: 175.598%;
  max-width: 100%;
}
.listening_items_pack_name .title {
  word-wrap: break-word;
  width: 100%;
}
.listening_items_pack_name .package {
  word-wrap: break-word;
  width: 100%;
}

/* =========================================== verification screen =========================== */

.verification_div_wrapp {
  text-align: center;
  margin: 0px auto;
  width: 528px;
  height: 603px;
  border-radius: 16px;
  opacity: 0.9;
  background: linear-gradient(
    107deg,
    rgba(165, 173, 176, 0.5) 0%,
    rgba(98, 105, 123, 0.5) 51.56%,
    rgba(15, 16, 31, 0.5) 100%
  );
  box-shadow: 0px 11px 19px -1px rgba(0, 0, 0, 0.24);
}
.verify_padd_tb_50 {
  padding: 50px 0px !important;
}
.verification_div_wrapp .identity_logo {
  width: 72px;
  margin: 0px auto;
  padding: 33px 0px 28px 0px;
}
.verification_div_wrapp .identity_logo img {
  height: 100%;
  width: 100%;
}
.verification_div_wrapp .identity_title {
  color: #fff;
  text-align: center;
  font-family: "AnebaNeue";
  font-weight: 600;
  font-size: 36px;
  font-style: normal;
  line-height: 120.6%; /* 43.416px */
  margin-bottom: 28px;
}
.verification_div_wrapp .identity_para {
  width: 76%;
  margin: 0px auto;
  color: #cbd8e7;
  font-family: "AnebaNeue";
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 156.8%; /* 25.088px */
}
.verification_div_wrapp .identity_para .iden_para_mb {
  margin-bottom: 28px !important;
}
.identity_btns {
  display: flex;
  justify-content: space-between;
  width: 76%;
  margin: 69px auto 0px;
}
.identity_btns .identity_btns_btn {
  color: #fff;
  text-align: center;
  font-family: "AnebaNeue";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 138.8%; /* 33.312px */
  width: 48%;
  height: 64px;
  border-radius: 8px;
}
.identity_btns .identity_btns_back {
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #b6acbb 0%,
    #4c444e 41.67%,
    #252231 100%
  );
  box-shadow: 0px 15px 13px 1px rgba(0, 0, 0, 0.32) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.identity_btns .identity_btns_proceed {
  background: radial-gradient(
    100% 100% at 50% 0%,
    #ffd951 6.77%,
    #cb4848 99.48%
  );
  color: #570f0b !important;
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
}

/* single product model */
.modal-open {
  padding-right: 0px !important;
}
.modal {
  display: block !important;
  overflow-y: hidden !important;
}
.digital_product_model_wrapp {
  min-height: 200px;
}
.digital_product_model .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100dvh;
}
.digital_product_model .modal-content {
  background-color: transparent !important;
}
.digital_product_model .digital_product_model_body {
  border-radius: 16px;
  background: linear-gradient(
    120.09deg,
    rgba(49, 52, 66, 0.9) 7.14%,
    rgba(18, 73, 90, 0.9) 45.56%,
    rgba(44, 45, 75, 0.9) 81.66%
  );
  box-shadow: 0px 11px 19px -1px rgba(0, 0, 0, 0.24);
}
.digital_product_model_body {
  padding: 40px 0px;
  color: white;
}
.digital_product_model_body .cross_img {
  display: none;
}
.digital_product_model_body_inner {
  width: 80%;
  margin: 20px auto 20px;
  padding: 30px 0px;
  text-align: center;
}
.digital_product_model_body_inner .logoImg {
  width: 56px;
  height: 66px;
  margin: 20px auto;
}

.digital_product_model_body_inner .digital_rem {
  display: flex;
  justify-content: space-between;
}
.digital_rem {
  margin-top: 33px;
}
.digital_rem p {
  font-weight: 600;
  color: #cbd8e7;
}
.digital_rem .digital_rem_left {
  text-align: left;
}
.digital_rem .digital_rem_right {
  text-align: right;
}
.digital_product_model_body_inner .download_beats_popbtn {
  width: 100%;
  height: 64px;
  margin-top: 40px;
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #bbb3be 0%,
    #575859 36.98%,
    #2f2f30 68.75%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 15px 13px 1px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
}
.centerLogo-show {
  width: 100%;
  height: 50dvh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-us-wrapper {
  margin-top: 60px;
}
