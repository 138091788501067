@media screen and (max-width: 2560px) {
  .cart-list-main {
    width: 45% !important;
  }
}
@media screen and (max-width: 1440px) {
  .cart-list-main {
    width: 46% !important;
  }
}
@media screen and (max-width: 1025px) {
  .login-form {
    width: 43% !important;
  }
  .showeye {
    width: 3vw;
  }
  .login-logo {
    width: 72px;
    margin: auto;
  }
  .sign_in_desc {
    font-size: 1.5vw;
  }
  .cart-list-main {
    width: 55% !important;
  }
  .cart_card_img {
    width: 80px !important;
    height: 80px !important;
  }
  .cart_card_box {
    padding-right: 10px !important;
  }
}

@media screen and (max-width: 768px) {
  /* Login CSS */
  .login-form {
    width: 56% !important;
  }
  .showeye {
    width: 3vw;
  }

  .sign_in_desc {
    font-size: 2vw;
  }
  .f24 {
    font-size: 20px !important;
  }
  .f32 {
    font-size: 26px !important;
  }
  .f20 {
    font-size: 15px !important;
  }
  .f14 {
    font-size: 10px !important;
    margin-bottom: 5px;
  }

  /* Cart CSS */

  .cart-body {
    padding: 10% 5% !important;
  }

  .cart-card {
    padding: 10px 5px !important;
  }

  .cart-card-price-span {
    width: auto !important;
    height: 30px !important;
    font-size: 12px !important;
  }
  .coinbox {
    width: auto !important;
    height: 30px !important;
    padding-left: 22px !important;
  }
  .coinbox img {
    top: 0px;
    left: -4px !important;
    width: 28px !important;
  }
  .coinbox p {
    font-size: 10px !important;
    margin-right: 7px !important;
  }
  .cart-qty-btn {
    height: 40px !important;
  }
  .cart-card-qty-show {
    width: 40px !important;
  }
  .cart-list-main {
    width: 65% !important;
  }
  .cart_card_img {
    width: 65px !important;
    height: 70px !important;
  }
}

@media screen and (max-width: 520px) {
  .cart-list-main {
    width: 100% !important;
    box-shadow: none !important;
    background: none !important;
    border-radius: 0px !important;
  }
  .cart-list-main {
    padding-right: 0px;
    padding-left: 0px;
  }

  .desktop_none {
    display: block !important;
    min-width: 28px;
  }
  .headline-cart {
    margin-bottom: 30px;
    justify-content: space-between !important;
  }
  .mobile_none {
    display: none !important;
  }
  .f20 {
    font-size: 13px !important;
  }

  .newpassword-btn {
    margin-top: 121px !important;
  }
  .reset-email {
    display: block !important;
  }

  .showeye {
    width: 6vw;
  }
  .signin-des {
    font-size: 16px;
    line-height: 19px;
    color: #aaaaaa;
    margin-top: 5%;
    float: left;
    font-family: "AnebaNeue";
    margin-bottom: 8%;
  }
  .dis-none {
    display: none;
  }

  .center-side-login {
    width: 100%;
    height: auto;
  }
  .login-form {
    background: transparent !important;
    opacity: 0.95 !important;
    box-shadow: none !important;
    padding-top: 0% !important;
    padding-bottom: 30% !important;
    left: 0%;
    margin-top: 0;
    width: 100% !important;
    border-radius: 0;
  }
  .signin {
    margin-top: 107px;
  }
  .login-body {
    background-image: url("./images/mobileloginpage.png");
    background-size: cover;
    background: linear-gradient(
      120.09deg,
      rgba(137, 142, 143, 0.5) 7.14%,
      rgba(79, 80, 81, 0.5) 45.56%,
      rgba(15, 16, 31, 0.5) 81.66%
    );
    box-shadow: 0px 11px 19px -1px rgba(0, 0, 0, 0.24);
  }
  .wrap-login-body {
    padding-top: 10.5%;
  }
  .login-logo {
    width: 90px;
    margin: auto;
  }
  .welcome-bk {
    margin-top: 6%;
    font-size: 32px;
    text-align: center !important;
  }

  .login-form-control {
    opacity: 0.7;
  }

  .sign_in_desc {
    font-size: 3.8vw;
    text-align: center;
  }
  .cart-headline-qty {
    margin-right: 7px !important;
  }
  .cart-card {
    padding: 10px 5px !important;
    width: 87% !important;
  }

  .cart-card-qty-box {
    display: none !important;
  }

  .cart-card-qty-show {
    font-size: 12px;
    padding: 4px;
    width: 50px !important;
    background: #1a1a1a !important;
  }

  .cart-qty-btn-mobile {
    margin-left: 5px !important;
  }
  .cart-qty-btn {
    height: 33px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: #0d0d0d !important;
  }
  .cart-card-price-span {
    width: auto !important;
    height: 25px !important;
    font-size: 10px !important;
  }
  .coinbox {
    width: auto !important;
    height: 25px !important;
    padding-left: 18px !important;
  }
  .coinbox img {
    top: 0px !important;
    left: -4px !important;
    width: 25px !important;
  }
  .coinbox p {
    font-size: 9px !important;
    margin-right: 7px !important;
  }
  .px-17{
    font-size: 13px !important;
  }
  .cart_card_img {
    width: 60px !important;
    height: 60px !important;
  }
}
@media screen and (max-width: 426px) {
  .sign_in_desc {
    font-size: 6vw;
  }
  .signin-des {
    font-size: 16px;
    line-height: 19px;
    color: #aaaaaa;
    margin-top: 5%;
    float: left;
    font-family: "AnebaNeue";
    margin-bottom: 8%;
  }

  .dis-none {
    display: none;
  }

  .center-side-login {
    width: 100%;
    height: auto;
  }
  .login-form {
    background: transparent;
    opacity: 0.95;
    box-shadow: none;
    padding-top: 0%;
    padding-bottom: 0%;
    left: 0%;
    margin-top: 0;
    width: 100%;
    border-radius: 0;
  }
  .signin {
    margin-top: 107px;
  }
  .login-body {
    /* background-image: url("./images/mobileloginpage.png"); */
    
  }
  .wrap-login-body {
    padding-top: 10.5%;
  }
  .login-logo {
    width: 90px;
    margin: auto;
  }
  .welcome-bk {
    margin-top: 6%;
  }
  .login-form-control {
    opacity: 0.7;
  }

  .sign_in_desc {
    font-size: 3vw;
  }
  .cart-card {
    padding: 3px 1px !important;
  }
  .f20 {
    font-size: 10px;
    margin-bottom: 0.2px;
  }
  .cart-qty-btn {
    height: 27px !important;
  }
}

@media screen and (max-width: 376px) {
  .sign_in_desc {
    font-size: 3.5vw;
  }

  .wrap-login-body {
    padding-top: 15%;
  }
  .login-logo {
    width: 76px;
    margin: auto;
  }
}
@media screen and (max-width: 321px) {
  .wrap-login-body {
    padding-top: 20%;
  }
  .login-logo {
    width: 76px;
    margin: auto;
  }

  .container-2 {
    padding-left: 7%;
  }
}
