* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  font-family: "AnebaNeue";
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin-bottom: 0 !important;
  /* font-family: 'Work Sans'; */

  font-family: "AnebaNeue";

  font-style: normal;
}

/* @import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;400;500;600;700;800;900&display=swap"); */

body,
html {
  /* font-family: "Work Sans"; */
  font-family: "AnebaNeue";
  background-color: black !important;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: "Work Sans"; */
  scroll-behavior: smooth;
  font-family: "AnebaNeue";

  border: none;
}
* {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

*::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
input:focus {
  outline: none;
}

.wrap-login-page {
  width: 100%;
  height: auto;
  display: flex;
}

.left-side-login {
  width: 8%;
  height: auto;
  padding: 2%;
  background-image: linear-gradient(
    180deg,
    #091323 0%,
    #160e24 52.35%,
    #0f1b25 100%
  );
  /* background-image: linear-gradient(red, yellow);   */
}

.border-liner {
  border-radius: 20px;
  /* Adjust the border radius as needed */
  overflow: hidden;
}

.desk-none {
  display: none !important;
}

.right-side-login {
  height: 1114px;
  padding: 2%;
  position: absolute;
  z-index: 10;
  width: 31%;
  right: 0%;
  background: linear-gradient(
    270deg,
    #150e25 31.96%,
    rgba(21, 14, 37, 0) 97.33%
  );
}

.center-side-login {
  width: 84%;
  height: 1114px;
}

.center-side-login-img {
  width: 100%;
  height: 1114px;
}

.login-form {
  padding: 47px 0% 47px 0%;
  background: linear-gradient(
    120.09deg,
    #4b5360cc 7.14%,
    #000c 81.66%
  ) !important;
  box-shadow: 0 11px 19px -1px #363e493d;
  border-radius: 16px;
  /* position: absolute;
    top: 0%;
    left: 37%;
    margin-top: 246px; */
  width: 29%;
}

.login-logo {
  width: 53px;
  margin: auto;
}

.login-logo img {
  width: 100%;
}

.forgot-bk2 {
  font-family: "AnebaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 120.6%;
  margin-top: 4%;
  margin-bottom: 4% !important;
  text-align: center;

  color: #ffffff;
}

.left {
  text-align: left !important;
}

.login-field {
  width: 87%;
  margin: auto;
}

.mt-resend {
  margin-top: 65px !important;
}

.login-form-control {
  background-color: black !important;
  border: 2px solid #343d49 !important;
  box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.16) !important;
  border-radius: 12px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  height: 64px !important;
  font-weight: 500 !important;
  font-family: "AnebaNeue";
  font-style: normal;

  color: #cbd8e7 !important;
}

.login-label label {
  font-weight: 400 !important;
  font-family: "AnebaNeue";
  font-style: normal;
  line-height: 14px !important;
  top: 7% !important;

  color: #ffffff !important;
}

.for-mar {
  margin-bottom: 5% !important;
}

.signin {
  width: 100%;
  border-radius: 8px;
  height: 64px;
  font-size: 24px;
  line-height: 137.3%;
  text-align: center;
  color: #000000;
  font-size: bold;
  font-weight: 600;
  margin-top: 36px;
  /* font-family: 'AnebaNeue-SemiBold'; */
  font-family: "AnebaNeue";
  /* background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #fff6e0 0%,
    #8eef9e 14.58%,
    #006088 100%
  ) !important;
  box-shadow: 0px 6px 10px 1px rgba(0, 0, 0, 0.32) !important; */

  background: radial-gradient(
    100% 100% at 50% 0%,
    #ffd951 6.77%,
    #cb4848 99.48%
  );
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  color: #000000;
}

.signin:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 15px 13px 1px rgba(0, 0, 0, 0.32);
  color: white !important;
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #8a7f94 0%,
    #4c444e 41.67%,
    #241e35 100%
  ) !important;
}

.signin-des {
  font-size: 16px;
  line-height: 19px;
  color: #aaaaaa;
  margin-top: 5%;
  float: left;
  font-family: "AnebaNeue";
}

.mb-0_5 {
  margin-bottom: 0.5rem;
}

.text-yellow {
  text-decoration: underline;
  /* color: #01a4b7 !important; */
  color: #e9a14b !important;
  margin-left: 1%;
  cursor: pointer;
  font-family: "AnebaNeue";
}

/* .login-body{
    min-height: 100vh;
    max-height: 180vh;
    background-color: #150E25;
    background-image: url("./images/loginpage.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}
.wrap-login-body{
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
} */
.mb_1 {
  margin-bottom: 2%;
}

.text-center {
  text-align: center !important;
}

.showeye {
  position: absolute;
  right: 8px;
  top: 20px;
  cursor: pointer;
  width: 23px;
}

.login-bottom {
  display: flex;
  width: 94%;
  margin: auto;

  justify-content: space-between;
  margin-top: 4%;
}

.login-bottom h1 {
  font-family: "AnebaNeue";
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #aaaaaa;
  cursor: pointer;
  font-weight: 500;
}

.forgot-bk {
  font-family: "AnebaNeue";
  font-size: 16px;
  line-height: 156.8%;
  color: #cbd8e7;
  text-align: center;
}

.mt-reset {
  margin-top: 67px !important;
}

.back {
  width: 100%;
  display: flex;
  margin-top: 4%;
}

.back h1 {
  font-family: "AnebaNeue";
  font-size: 16px;
  line-height: 24px;
  color: #aaaaaa;
  margin-top: 1%;
  cursor: pointer;
}

.back img {
  width: 23px;
  height: 23px;
  margin-top: 1%;
  margin-right: 1%;
  cursor: pointer;
}

.container-2 {
  display: block;
  position: relative;
  padding-left: 6%;
  /* margin-bottom: 12px; */
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "AnebaNeue";
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #aaaaaa;
}

/* Hide the browser's default radio button */
.container-2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #aaaaaa;
  border-radius: 4px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-2 input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-2 .checkmark:after {
  top: 2px;
  left: 2px;

  width: 8px;
  height: 8px;

  background: #e9a14b;
  border-radius: 2px;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: #fff !important;
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  background-color: transparent !important;
}

.formtop {
  margin-top: 3%;
}

/* 
home page 

*/
.font-20 {
  font-family: "AnebaNeue";
  font-weight: 500;
  font-size: 20px;
}

.font-16 {
  font-family: "AnebaNeue";
  font-weight: 600;
  font-size: 16px;
}

.font-16-medium {
  font-family: "AnebaNeue";
  font-weight: 500;
  font-size: 16px;
}

.font-14 {
  font-family: "AnebaNeue";
  font-weight: 500;
  font-size: 14px;
}

.font-24 {
  font-family: "AnebaNeue";
  font-weight: 500;
  font-size: 24px;
}

.font-48 {
  font-family: "AnebaNeue";
  font-weight: 600;
  font-size: 48px;
}

.pt120 {
  padding-top: 120px;
}

.mt12 {
  margin-top: 12px;
}

.mt25 {
  margin-top: 25px;
}

.home-notification {
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(270deg, #01a4b7 48%, #006088 100%); */
  background: #1e1d22;
}

.home-notification h1 {
  text-align: center;
  color: #cbd8e7;
  line-height: 168.8%;
}

.home-notification h1 strong {
  text-decoration: underline;
}
.home-notification h1 strong a {
  color: #e9a14b;
}
.satellite-banner {
  background-image: url("./HomeAssets/assets/images/banner-bg.png");
  background-size: cover;
  background-color: black;
  background-position: center;
  position: relative;
  min-height: 680px;
  /* min-height: 100vh;
  max-height: 180vh; */
}

.wrap-beats {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrap-beats h1 {
  color: #fff;
  line-height: 105.8%;
}

.wrap-beats p {
  line-height: 119.3%;
  /* or 29px */

  text-align: center;

  color: #cbd8e7;
}

.themebtn-green {
  width: 192px;
  height: 64px;
  /* background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #fff6e0 0%,
    #8eef9e 14.58%,
    #006088 100%
  ); */
  color: #570f0b;
  background: radial-gradient(
    100% 100% at 50% 0%,
    #ffd951 6.77%,
    #cb4848 99.48%
  );
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  border: none;
  border-radius: 8px;
  font-family: "Aneba Neue";
  font-size: 24px;
  line-height: 140.8%;
  text-align: center;
  font-weight: 600;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-style: normal;
}

.themebtn-green:hover {
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #bbb3be 0%,
    #575859 36.98%,
    #2f2f30 68.75%
  );

  color: #fff;
}
.satalite-header-menu ul {
  display: flex;
}
.satalite-header-menu ul .nav-link {
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  line-height: 112.3%;
  text-align: center;
  color: #ffffff;
  margin-right: 16px;
  border-bottom: 1px solid transparent;
  position: relative;
}

.satalite-header-menu ul .nav-item.active .nav-link:after {
  bottom: -10px;
  opacity: 1;
}

.satalite-header-menu ul .nav-item.active .nav-link {
  color: #ff0044 !important;
}

.satalite-header-menu ul .nav-item:hover .nav-link {
  color: #ffd951 !important;
}

.satalite-header-menu ul .nav-item:hover .nav-link:after {
  bottom: -10px;
  opacity: 1;
}

.satalite-header-menu ul .nav-link:after {
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #ffd951;
  opacity: 0;
  -webkit-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

.btn-w280 {
  width: 280px;
  height: 64px;
  box-shadow: 0px 4px 9px 2px rgba(0, 0, 0, 0.32);
}

.browse-products {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 6%;
  padding-bottom: 11%;
}

.card-product {
  width: 19%;
  height: 224px;
  cursor: pointer;
  border-radius: 8px;
  position: relative; 
}

.discount-tag {
  position: absolute;
  top: 3px;
  right: 0px;
  width: 90px;  
  height: 25px;
  /* background-image: url('./homeImages/promotionTag.png'); */
  /* background-size: cover; */
  /* background-position: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 10px 5px 5px rgb(0, 0, 0); */
  /* transform: rotate(-45deg); */
}

.discount-tag span {
  color: #570f0b;
  font-size: 10px;
  padding: 2px 7px;
  font-weight: bold;
  border-radius: 20px;
  text-align: center;
  /* background: red; */
  background: radial-gradient(
    100% 100% at 50% 0%,
    #ffd951 6.77%,
    #cb4848 99.48%
  )!important;
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32) !important;
  /* transform: rotate(-45deg); */
  /* margin-top: -23px; */
  /* margin-left: -19px; */
  
text-shadow: rgb(255, 255, 255) 1px 0 10px ;
}

.card-product-img {
  width: 100%;
}

.card-product-img img {
  width: 100%;
  border-radius: 8px;
}

.card-product h1 {
  line-height: 163.6%;
  color: #cbd8e7;
  margin-top: 7px;
}

.card-product p {
  line-height: 120.6%;
  color: #aaaaaa;
  margin-top: 7px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.get-the-bundle {
  padding-top: 6%;
  /* background-image: url("./homeImages/bgbundle.png"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* min-height: 40vh;fgsgs */
  background-image: linear-gradient(
      180deg,
      #161822 0%,
      rgba(22, 24, 34, 0) 100%
    ),
    linear-gradient(180deg, #161822 0%, rgba(22, 24, 34, 0) 100%),
    url("./homeImages/bgbundle.png");
  /* max-height: 180vh; */
  padding-bottom: 4%;
}

.card-bundle {
  width: 100%;
  margin: auto;
  /* background: linear-gradient(
    120.09deg,
    rgba(37, 40, 57, 0.8) 7.14%,
    rgba(57, 61, 72, 0.8) 45.56%,
    rgba(15, 16, 31, 0.8) 81.66%
  ); */
  /* box-shadow: 0px 11px 19px -1px rgba(0, 0, 0, 0.24); */
  background: linear-gradient(120.09deg, #4b5360cc 7.14%, #000c 81.66%);
  box-shadow: 0 11px 19px -1px #363e493d;
  opacity: 0.9;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  padding: 5% 4% 5% 4%;
}

.first-card-bundle {
  width: 65%;
}

.carfting-dn {
  display: none;
}

.ddn {
  display: none;
}

.mdn {
  display: block;
}

.second-card-bundle {
  width: 30%;
}

.second-card-bundle img {
  width: 100%;
}

.first-card-bundle h1 {
  line-height: 105.8%;
  color: #ffffff;
}

.first-card-bundle p {
  line-height: 119.3%;
  /* or 29px */

  color: #cbd8e7;
}

.btn-w224 {
  width: 224px;
  height: 64px;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.32);
}

.body-section-crafting {
  background-color: black;
  width: 100%;
}

.wrap-carfting-card {
  width: 100%;
  margin: auto;
  display: flex;
  padding-top: 9%;
  padding-bottom: 9%;

  justify-content: space-between;
}

.card1-carfting {
  width: 46%;
}

.card1-carfting h1 {
  line-height: 116.8%;
  color: #ffffff;
  font-size: 47px !important;
}

.card1-carfting p {
  line-height: 119.3%;
  color: #cbd8e7;
  margin-top: 3%;
}

.card1-carfting img {
  width: 100%;
  border-radius: 15px;
}

.mt15 {
  margin-top: 15%;
}

.mt41 {
  margin-top: 41%;
}

.theme-container-newfooter {
  width: 75%;
  margin: auto;
}

.body-join {
  background: linear-gradient(
    120.09deg,
    rgba(37, 40, 57, 0.8) 7.14%,
    rgba(57, 61, 72, 0.8) 45.56%,
    rgba(15, 16, 31, 0.8) 81.66%
  );
  opacity: 0.9;
  box-shadow: 0px 11px 19px -1px rgba(0, 0, 0, 0.24);
  border-radius: 16px;
  width: 100%;
  margin: auto;
  padding: 5% 4% 5% 4%;
}

.body-join h1 {
  line-height: 105.8%;
  color: #ffffff;
}

.body-join p {
  line-height: 119.3%;
  color: #cbd8e7;
  margin-top: 1%;
}

.btn-w216 {
  width: 216px;
  height: 64px;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.32);
}

.st-footer-logo {
  width: 5%;
}

.st-footer-logo img {
  width: 70px;
  height: 90px;
}

.theme-container-h1 {
  line-height: 163.6%;
  color: #cbd8e7;
  margin-top: 3%;
}

.theme-container-p {
  line-height: 120.6%;
  /* or 19px */
  margin-top: 1%;

  color: #aaaaaa;
}
.mt-nne {
  margin-top: 4px !important;
}
.mt3 {
  margin-top: 3% !important;
}

.ml13 {
  /* margin-left: 13%; */
  margin-top: 6%;
}

.satalite-header {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.satalite-header-menu {
  /* width: 34%;
   */
  display: flex;
  align-items: center;
}

.wrap-header-btn {
  display: flex;
  /* width: 35%; */
  justify-content: space-between;
}

.satalite-header-logo {
  /* width: 30%; */
  display: contents;
  cursor: pointer;
}

.satalite-header-logo img {
  margin-left: 195px;
  /* width: 4vw; */
  height: 90px;
  width: 70px;
}

.menu-a {
  font-family: "AnebaNeue";
  font-size: 24px;
  line-height: 117.6%;
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
}

.header-btn-login {
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #bbb3be 0%,
    #575859 36.98%,
    #2f2f30 68.75%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  color: #fff;
  margin-right: 5%;
}

.header-btn-login:hover {
  color: #570f0b;
  background: radial-gradient(
    100% 100% at 50% 0%,
    #ffd951 6.77%,
    #cb4848 99.48%
  );
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
}

.ml3 {
  margin-left: 12%;
}

.pt3 {
  padding-top: 3%;
}

.footer-list2 {
  text-align: center;
}

.footer-list2 li a {
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 20px;
  line-height: 105.8%;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 25px !important;
  display: inline-flex;
}
.footer-list2 li a:hover {
  color: #ffd951;
}
/* forgot screen */
.forgot-body {
  min-height: 100vh;
  max-height: 180vh;
  background-color: #150e25;
  background-image: url("../assets/images/loginpage.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.purchasing-forgort {
  min-height: 100vh;
  max-height: 180vh;
}

.forgot-form {
  padding: 47px 0% 47px 0%;

  background: linear-gradient(
    120.09deg,
    #4b5360cc 7.14%,
    #000c 81.66%
  ) !important;
  box-shadow: 0px 11px 19px -1px rgba(0, 0, 0, 0.24) !important;
  border-radius: 16px;
  width: 460px;
}
.input-mask-dec-none {
  text-decoration: none !important;
}
.signin_fb {
  width: 100%;
  background: #1877f2;
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  height: 64px;
  font-size: 20px;
  line-height: 137.3%;
  text-align: center;
  color: #fff;
  font-size: bold;
  font-weight: 700;
  margin-top: 10px;
  /* font-family: 'AnebaNeue-SemiBold'; */
  font-family: "AnebaNeue";
}
.signin_fb img {
  width: 7%;
  margin-right: 1%;
  margin-bottom: 1%;
}
input[type="text"]:disabled {
  background: rgba(19, 21, 29, 0.68);
}

.coinbox2 {
  width: auto !important;
  height: 40px;
  padding-left: 35px;
  background: #2e363c;
  border: 2px solid #373f46;
  border-radius: 8px;
  position: relative;
  display: grid;
  font-weight: 600;
  line-height: 129.8%;
  text-align: right;
  color: #5f657a;
  align-items: center;
}
.coinbox2 img {
  position: absolute;
  top: -2px;
  left: -4px;
  width: 45px;
}
.coinbox2 p {
  margin-bottom: 0px;
  font-size: 14px;
  margin-right: 7px;
}

.crafting-empty-desc {
  width: 100%;
  height: 50px;
}
.reText {
  color: #cbd8e7;
}
.agreeText {
  color: #cbd8e7;
}

.about-faq button {
  background-color: #2e363c !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 120.6% !important;
  color: #ffffff !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.about-faq button:not(.collapsed) {
  color: #fff !important;
  border-bottom-left-radius: 0px !important;
  box-shadow: none;
  border-bottom-right-radius: 0px !important;
}
.accordion-item {
  background-color: transparent !important;
}
.about-faq-body {
  font-weight: 500 !important;
  font-style: normal !important;
  font-family: "Aneba Neue" !important;
  font-size: 16px !important;
  line-height: 119.3% !important;
  color: #ffffff !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-button::after {
  filter: invert(1) brightness(10);
}
.accordion-item {
  margin-bottom: 9px;
  border: 1px solid transparent !important;
  border-radius: 8px !important;
}
.accordion-item:not(:first-of-type) {
  border-top: 1 !important;
}
.wrap-faq {
  width: 100%;
  display: flex;
}
.sec1-faq {
  width: 50%;
}
.sec2-faq {
  width: 50%;
  margin-bottom: 2%;
}
.form-control::placeholder {
  color: #959191 !important;
}

#container {
  /* min-height: 200px; Set a minimum height to ensure visibility */
  /* width: 100%; 
  border: none;
  overflow: hidden; Ensure the content isn't clipped */
}
.coinflow-form{
  height: 600px !important;
  width: 100% !important;
}
.max-w-400{
  width: auto;
  max-width: 400px !important;
}
.hover-voucher{
  cursor: pointer;
}
.voucher-wrap{
  background-image: url("../assets/images/Subtract.png");
background-size: cover;
background-repeat: no-repeat;
height: 115px;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
cursor: pointer;
}
.offcanva-coupon{
  /* background-color: rgba(0, 0, 0) !important; */
  background: linear-gradient(120.09deg, #4b5360cc 7.14%, #000c 81.66%) !important;
}
.my-v-p{
  font-size: 20px !important;
  color: #fff !important; 
}

.offcanvas-header .btn-close{
  filter: invert(1) !important; /* Makes the default close button white */

}
.coupon-line{
  width: 66%;
  display: flex;
}
.voucher-detail{
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.coupon-valid{
  font-size: 10px;
    color: #b2afaf;
}
.coupon-dis{
  font-size: 22px;
  font-weight: 600;
}
.coupon-code{
  font-size: 17px;
    font-weight: 500;
}
.delete-vocher{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.delete-vocher p{
  color: rgb(233, 161, 75);
  font-size: 16px;
  font-weight: 600;
}
.hover-voucher:hover{
  color: rgb(233, 161, 75) !important;
}
.f20-sub {
  font-weight: 600;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 20px;
  line-height: 119.3%;
  color: var(--litgray);
}
.f32-sub {
  font-style: normal;
  font-weight: 500;
  font-family: "AnebaNeue";
  font-size: 24px;
  line-height: 132.3%;
  color: var(--litgray) !important;
}