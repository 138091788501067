@media (max-width: 1200px) {
  /* about us page */

  .elev_beats_wrapper {
    width: 300px;
  }
  .elev_beats_h1 {
    font-size: 32px;
  }
  .img_blocks_wrap {
    margin-left: 0px !important;
  }
  .elev_beats_padding_right {
    padding-left: 0px !important;
  }
  /* single product */
}
@media (max-width: 1024px) {
  /* For screens with a width of 1024px or more */
  /* .purchase_card_wrapper {
    grid-template-columns: repeat(4, minmax(204px, 1fr));
  } */
  .product_details .product_img {
    height: 310px;
    flex-basis: 30%;
  }
  .product_details .product_detail {
    width: 70%;
    margin: 0px 0px 0px 30px;
  }
}
@media (max-width: 998px) {
  /* about us page */

  .contact_wrapp_left {
    display: none;
  }

  .elev_beats_h1 {
    text-align: center;
    font-size: 32px;
  }
  .contact_wrapp_mobile {
    display: block;
    text-align: center;
  }
  .contact_wrapp_mobile .contact_span_aboutUs_satl_p_mobile {
    font-family: "AnebaNeue";
    /* min-height: 176px; */
    font-weight: 500;
    font-style: italic;
    font-size: 24px;
    line-height: 116.8%;
    margin-bottom: 0px;
    color: #ffffff;
  }
  .contact_p_team_mobile {
    font-family: "AnebaNeue" !important;
    height: 24px !important;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 147.8% !important;
    /* identical to box height, or 24px */
    color: #ffffff !important;
    margin-top: 16px;
  }
  .img_blocks_wrap {
    width: 100%;
    height: 456px;
  }
  .img_blocks_wrap img {
    width: 100%;
    height: 100%;
  }
  .elev_beats_wrapper {
    width: 100%;
  }
  .elev_beats_padding_right {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
  }
  .about_us_contact_wrapp {
    padding: 10px 0px;
  }
  .about_us_commitment_wrapp {
    padding: 10px 0px;
  }
  .contact_mobile {
    margin: 32px 0px;
  }
  /* signup header */
  .signup_header_conatiner {
    display: none;
  }

  /*  */
  .single_product_wrapper .product_details {
    display: flex;
    flex-direction: column;
  }
  .product_details .product_img {
    width: 80%;
    position: relative;
    margin: 50px auto 30px;
  }
  .product_details .product_detail {
    width: 100%;
    max-width: unset;
    margin: 10px 0px;
  }
  /* single product */
  .cart_btn_wrapper_sp {
    margin-top: 32px;
    width: 100%;
  }
  .cart_btn_wrapper_sp button {
    width: 100%;
    /* margin: 0px 10%; */
  }
}
@media (max-width: 768px) {
  /* about us page */
  .about_us_commitment_left {
    height: 400px;
  }
  .satalite_header_wrap {
    display: block;
  }
  /* purchase success page */
  .close_btn {
    display: block;
  }
  .payment_screeen {
    width: 100%;
    background: none;
    box-shadow: none;
  }
  .purchasing-btns {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .purchasing-btns .purchase_succ_btn {
    width: 100%;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  .theme-container-padd-100 {
    /* padding-top: 0px !important; */
  }
  .satalite_header_wrap {
    padding-top: 0px !important;
  }
  .cart_btn_wrapper_sp button {
    width: 100%;
    margin: 0px 0%;
  }

  /* For screens with a width of 768px or more */
  /* .purchase_card_wrapper {
    grid-template-columns: repeat(3, minmax(204px, 1fr));
  } */

  .filter_product_wrapper .filters_wrapperleft {
    display: none;
  }
  .ml-filt-none {
    margin-left: 0px !important;
  }
}
@media (max-width: 599px) {
 
  /* header show MV */
  .mobile_menu_wrap {
    display: block !important;
  }
  .contact-us-wrapper {
    margin-top: 0px;
  }
  /* about us page */

  .img_blocks_wrap {
    height: 400px;
    margin-top: 20px;
  }

  .elev_beats_h1 {
    min-height: 128px;
    margin-bottom: 28px !important;
    /* margin-top: 40px; */
  }
  .elev_beats_p_mbtom_5 {
    margin-bottom: 5px;
  }
  .about_us_commitment_left {
    height: 440px;
    margin: 10px 0px 30px;
  }
  .about_us_commitment_wrapp {
    margin-top: 27px;
  }
  .about_us_cus_title {
    height: 40px;
    margin-bottom: 8px;
  }
  .about_us_cus_title_text-desk {
    display: none;
  }
  .about_us_cus_title_text-mob {
    display: block;
  }
  .elev_beats_padding_right {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
  }

  /* chnaging bg's in mobile view  */
  .satellite_blend {
    background: linear-gradient(180deg, rgba(10, 10, 10, 0) 0%, #0a0a0a 100%);
    /* background: black; */
  }
  .satellite_blend_mobile {
    position: absolute;
    /* height: 40%; */
    /* min-height: 912px; */
    width: 100%;
    z-index: -1;
    /* background: linear-gradient(#010103, #1e5056, #0e0f34, #323159); */
    background-image: url("../assets/images/bg-mob-signup.png") !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .satellite_blend::before {
    background-image: none;
    /* mix-blend-mode: unset; */
  }
  .satellite_blend_about::before {
    background-image: url("../assets/images/bg-mob-signup.png");
    width: 100%;
    height: 46%;
    min-height: 46dvh;
  }
  .bottom_light_wrapper {
    background: none;
  }

  /* payment screen */
  .payment_screeen .success_logo {
    margin-top: 0px;
  }
  .satellite_blend_mobile_pay_succ {
    height: 100%;
    min-height: 100dvh;
    background-image: url("../assets/images/bg-mob-signup.png") !important;
  }
  .payment_screeen .success_tittle h1 {
    font-size: 32px;
    line-height: 38px;
  }
  .payment_screeen .success_para {
    margin-top: 0px;
  }
  .payment_screeen .success_para p {
    font-size: 16px;
    line-height: 119.3%;
  }
  .center-img {
    margin: 45px auto 160px;
  }
  /* single product  */
  .product_details .product_detail .p-details {
    font-size: 16px;
  }
  .product_details .product_detail .p-details .read-more {
    /* color: #01a4b7; */
    color: #e9a14b !important;
    text-decoration: underline;
  }
  .product_details .product_detail .p-details-con-desk {
    display: none;
  }
  .product_details .product_detail .p-details-con-mob {
    display: block;
  }
  .satellite_blend_mobile_single_prod {
    height: 100%;
  }
  .theme-con-padd-btm-100 {
    padding-bottom: 100px !important;
  }
  /* */
  .purchase_card_wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(48%, 2fr));
    grid-gap: 10px;
  }
  .purchase_card_inner .purchaseCard_img {
    /* height: 192px; */
  }
  .pageTittle_h1 h1 {
    font-size: 32px;
  }
  .product_details .product_detail .p-details {
    min-height: 0px;
  }

  /* identification screen */
  .verification_div_wrapp {
    width: 94%;
    background: none;
    box-shadow: none;
    border-radius: 0px;
  }
  .verification_div_wrapp .identity_logo {
    padding-top: 149px;
  }
  .verification_div_wrapp .identity_para {
    width: 100%;
  }
  .identity_btns {
    margin-top: 143px;
    width: 100%;
  }

  .digital_product_model_wrapp {
    min-height: 0px;
  }
  .digital_product_model_body .cross_img {
    display: block;
    float: right;
    height: 14px;
    width: 14px;
  }
  .single_product_wrapper {
    min-height: 0px !important;
  }
  .digital_product_model .modal-dialog {
    margin: 10px;
  }
  .digital_product_model_body_inner {
    margin: 0px auto;
    width: 100%;
  }
}
@media (max-width: 390px) {
  .purchase_card_inner .purchaseCard_img {
    /* height: 175px; */
  }
}
