@media screen and (max-width: 2560px) {
}

@media screen and (max-width: 1025px) {
  .login-form {
    width: 43%;
  }

  .back img {
    width: 2.5vw;
    height: 2.5vw;
    margin-top: 0.5%;
  }

  .showeye {
    width: 23px;
  }

  .login-logo {
    width: 72px;
    margin: auto;
  }

  .themebtn-green {
    width: 172px;
    height: 56px;
    font-size: 21px;
  }

  .satalite-header-logo img {
    /* width: 5vw; */
    width: 7vw;
  }

  .font-48 {
    font-size: 40px;
  }

  .font-20 {
    font-size: 17px;
  }

  .btn-w280 {
    width: 246px;
  }

  .card1-carfting h1 {
    font-size: 34px !important;
  }

  .font-24 {
    font-size: 19px;
  }
}

@media screen and (max-width: 768px) {
  .login-form {
    width: 56%;
  }

  .showeye {
    width: 23px;
  }

  .back img {
    width: 3vw;
    height: 3vw;
    margin-top: 1%;
  }

  .mobile_menu_wrap {
    display: inline-block !important;
  }

  .satalite-header {
    display: none;
  }
  .footer-wrap {
    margin-bottom: 80px;
  }
  .wrap-faq {
    flex-direction: column;
  }
  .sec1-faq {
    width: 100%;
    margin-bottom: 6%;
  }
  .sec2-faq {
    width: 100%;
    margin-bottom: 2%;
  }
  .stripe-form-btn{
    font-size: 20px !important;
  }
}

@media screen and (max-width: 520px) {
  /* .wrap-faq{
    flex-direction: column;
  }
  .sec1-faq{
    width: 100%;
    margin-bottom: 6%;
  }
  .sec2-faq{
    width: 100%;
    margin-bottom: 2%;
  } */
  .forgot-form {
    background: transparent !important;
    opacity: 0.95 !important;
    box-shadow: none !important;
    padding-top: 0% !important;
    left: 0%;
    margin-top: 0;
    width: 100% !important;
    border-radius: 0;
  }

  .browse-products {
    flex-wrap: wrap;
    width: 92%;
  }

  .card-product p {
    display: none;
  }

  .card-product-img {
    border: 4px solid #4e4e63;
    padding: 4%;
    border-radius: 13px;
  }

  .card-product {
    width: 48%;
    height: 280px;
  }

  .wrap-beats {
    width: 95%;
    margin: auto;
    padding-top: 50px !important;
  }
  .wrap-beats h1 {
    font-size: 30px !important;
  }

  .pt120 {
    padding-top: 0%;
  }

  .font-20 {
    font-size: 16px;
  }

  .satellite-banner {
    background-image: url("../assets/images/bg-mob-signup.png");
    padding-bottom: 16%;
  }

  .pt3 {
    padding-top: 9%;
  }

  .satalite-header {
    display: none;
  }

  .ddn {
    display: block;
  }

  .mdn {
    display: none;
  }

  .get-the-bundle {
    background-image: linear-gradient(
        180deg,
        #161822 0%,
        rgba(22, 24, 34, 0) 100%
      ),
      linear-gradient(180deg, #161822 0%, rgba(22, 24, 34, 0) 100%),
      url("./homeImages/getmb.png");
    padding-bottom: 9%;
  }

  .card-bundle {
    flex-direction: column-reverse;
    width: 94%;
    padding: 0%;
    background: transparent;
    box-shadow: none;
    border-radius: 0px;
  }

  .second-card-bundle {
    width: 92%;
    margin: auto;
  }

  .first-card-bundle {
    width: 100%;
    margin-top: 6%;
  }

  .font-48 {
    font-size: 32px;
  }

  .font-24 {
    font-size: 16px;
  }

  .first-card-bundle p {
    text-align: center;
    margin-top: 3%;
  }

  .home-notification {
    height: 40px;
  }

  .newpassword-btn {
    margin-top: 121px !important;
  }

  .reset-email {
    display: block !important;
  }

  .mt-resend {
    margin-top: 65px !important;
  }

  .mt-resend {
    margin-top: 211px !important;
  }

  .showeye {
    width: 23px;
  }

  .signin-des {
    font-size: 16px;
    line-height: 19px;
    color: #aaaaaa;
    margin-top: 5%;
    float: left;
    font-family: "AnebaNeue";
    margin-bottom: 8%;
  }

  .dis-none {
    display: none;
  }

  .desk-none {
    display: block !important;
  }
  .body-join-wrap-con {
    padding-top: 80px;
  }
  .center-side-login-img {
    height: auto;
  }

  .center-side-login {
    width: 100%;
    height: auto;
  }

  .login-form {
    background: transparent;
    opacity: 0.95;
    box-shadow: none;
    padding-top: 0%;
    padding-bottom: 0%;
    left: 0%;
    margin-top: 0;
    width: 100%;
    border-radius: 0;
  }

  .signin {
    margin-top: 107px;
  }

  /* .login-body{
    background-image: url("./images/mobileloginpage.png");
} */
  /* .wrap-login-body{
    padding-top: 22.5%;
} */
  .login-logo {
    width: 90px;
    margin: auto;
  }

  .welcome-bk {
    margin-top: 6%;
    font-size: 32px;
  }

  .login-form-control {
    opacity: 0.7;
  }

  .mt-reset {
    margin-top: 192px !important;
  }

  .back img {
    width: 23px;
    height: 23px;
    margin-right: 2%;
    cursor: pointer;
    margin-top: 1%;
  }

  .back h1 {
    font-family: "AnebaNeue";
    font-size: 16px;
    line-height: 24px;
    color: #aaaaaa;
    margin-top: 1%;
    cursor: pointer;
    margin-bottom: 5%;
  }

  .btn-w100 {
    width: 100%;
  }

  .bannar-btn {
    width: 93%;
    margin: auto;
  }

  .wrap-carfting-card {
    flex-direction: column-reverse;
    width: 93%;
  }

  .card1-carfting {
    width: 100%;
  }

  .card1-carfting h1 {
    display: none;
  }

  .mt15 {
    margin-top: 3%;
  }

  .card1-carfting p {
    display: none;
    margin-bottom: 6% !important;
  }

  .mbmt {
    margin-top: 10%;
  }

  .carfting-dn {
    display: block !important;
  }

  .card1-carfting h1 {
    font-size: 30px !important;
  }

  .mt41 {
    margin-top: 4%;
  }

  .footerrpading {
    padding-bottom: 17% !important;
  }

  .body-join {
    border-radius: 0;
    width: 100%;
    padding: 0% 4% 0% 4%;
  }
  .body-join-wrap-con {
  }

  .body-join p {
    display: none;
    text-align: center;
  }

  .body-join h1 {
    text-align: center;
  }

  .st-footer-logo {
    width: 17%;
    margin: auto;
    margin-bottom: 8%;
    margin-top: 4%;
  }

  .theme-container-h1 {
    text-align: center;
  }

  .theme-container-p {
    text-align: center;
    margin-bottom: 9% !important;
  }
  .mt-nne {
    margin: 4px 0px 8px 0px !important;
  }

  .footer-list {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .footer-list li {
    /* margin-right: 32px; */
    margin-top: 3%;
  }
  .forgot-body {
    background-image: url("../assets/images/mobileloginpage.png");
  }
  .stripe-form-btn {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 426px) {
  .signin-des {
    font-size: 16px;
    line-height: 19px;
    color: #aaaaaa;
    margin-top: 5%;
    float: left;
    font-family: "AnebaNeue";
    margin-bottom: 8%;
  }

  .mt-resend {
    margin-top: 206px !important;
  }

  .dis-none {
    display: none;
  }

  .desk-none {
    display: block;
  }

  .center-side-login-img {
    height: auto;
  }

  .center-side-login {
    width: 100%;
    height: auto;
  }

  .login-form {
    background: transparent;
    opacity: 0.95;
    box-shadow: none;
    padding-top: 0%;
    padding-bottom: 0%;
    left: 0%;
    margin-top: 0;
    width: 100%;
    border-radius: 0;
  }

  .signin {
    margin-top: 107px;
  }

  /* .login-body{
    background-image: url("./images/mobileloginpage.png");
} */
  .wrap-login-body {
    padding-top: 15.5%;
  }

  .login-logo {
    width: 90px;
    margin: auto;
  }

  .welcome-bk {
    margin-top: 6%;
  }

  .login-form-control {
    opacity: 0.7;
  }

  .mt-reset {
    margin-top: 192px !important;
  }

  .back img {
    width: 23px;
    height: 23px;
    margin-right: 2%;
    cursor: pointer;
    margin-top: 1%;
  }

  .back h1 {
    font-family: "AnebaNeue";
    font-size: 16px;
    line-height: 24px;
    color: #aaaaaa;
    margin-top: 1%;
    cursor: pointer;
    margin-bottom: 5%;
  }
}

@media screen and (max-width: 376px) {
  .newpassword-btn {
    margin-top: 97px !important;
  }

  .back img {
    width: 23px;
    height: 23px;
    margin-right: 2%;
    cursor: pointer;
    margin-top: 1%;
  }

  .mt-resend {
    margin-top: 164px !important;
  }

  .back h1 {
    margin-bottom: 10%;
  }

  .wrap-login-body {
    padding-top: 15.5%;
  }

  .login-logo {
    width: 76px;
    margin: auto;
  }

  /* .wrap-login-body {
        padding-top: 50.5%;
    } */
  .mt-reset {
    margin-top: 164px !important;
  }
}

@media screen and (max-width: 321px) {
  .wrap-login-body {
    padding-top: 50.5%;
  }

  .login-logo {
    width: 76px;
    margin: auto;
  }

  .mt-reset {
    margin-top: 98px !important;
  }

  .container-2 {
    padding-left: 7%;
  }

  .mt-resend {
    margin-top: 168px !important;
  }
}
