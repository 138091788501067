@media (max-width: 1880px) {
}

@media (max-width: 1780px) {
}

@media (max-width: 1680px) {
}

@media (max-width: 1560px) {
}

@media (max-width: 1480px) {
}

@media (max-width: 1367px) {
}

@media (max-width: 1280px) {
}

@media (max-width: 1199px) {
  .footer-bottom {
    margin-top: 98px;
  }
  .add_form,
  .custom_padding {
    max-width: 100%;
  }

  .add_form .w-400 {
    max-width: 100%;
  }

  .custom_padding [class*="col-"] {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .row.custom_padding {
    margin: 0 !important;
  }

  .custom_padding {
    padding: 0 0px;
    width: 100%;
    max-width: 100%;
  }

  .price_footer {
    margin-top: 32px;
  }

  .save_card_wrap {
    /* display: none; */
  }

  /* .add_new_form_Credit {
		display: block;
	} */

  .add_new_form_wrap {
    display: block;
  }

  .add_Card {
    min-height: auto;
  }

  .save_card.mt_32 {
    max-width: 568px;
    margin: 0 auto;
  }

  a.edit_text {
    margin-bottom: 32px;
    display: block;
  }
  .cus_mr_20 {
    margin-right: 12px !important;
  }
}

@media (max-width: 991px) {
  .footer-wrap {
    padding: 59px 0 33px;
  }

  .nav_list .f_24 {
    font-size: 19px;
  }

  .nav_list .mr_40 {
    margin-right: 20px;
  }
}

@media (max-width: 768px) {
  .footer-wrap .btnsupport {
    margin: 9px auto;
  }

  .header_wrap {
    display: none;
  }

  .card_info .f_20 {
    font-size: 16px;
  }

  .f_48 {
    font-size: 32px;
  }

  .mt_109 {
    margin-top: 0px !important;
    padding-top: 32px;
  }

  .save_card.mt_32 {
    margin-top: 0 !important;
  }
}

@media (max-width: 575px) {
  .footer_second {
    display: inline;
  }

  .mb_none {
    display: none;
  }

  ul.footer-list {
    justify-content: center;
  }

  .footer-wrap p,
  .footer-wrap h1 {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
  }

  img.logo_footer {
    width: 56px;
    height: 73px;
  }

  .desktop_logo {
    display: none;
  }

  .col-rev {
    flex-direction: column-reverse;
  }

  .footer-log {
    display: flex;
    justify-content: center;
    margin-bottom: 28px;
  }

  .mb_block {
    display: block;
  }

  .footer-wrap .btnsupport {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .footer-log {
    display: flex;
    justify-content: center;
    margin-bottom: 28px;
  }

  .footer-list li a {
    display: flex;
    justify-content: center;
  }

  .mobile_menu_wrap {
    display: block;
  }

  .mobile_menu_wrap {
    display: inline;
  }

  .w-176 {
    max-width: 88px;
    flex: 1 0 88px;
    width: 100%;
  }

  .mobile_none {
    display: none;
  }

  .box_info .w-256 {
    width: 194px;
    flex: 1 0 194px;
  }

  .Product_info_img {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    margin-right: 8px;
  }

  .box_info .mr_40 {
    margin-right: 6px !important;
  }

  .box_info .w-56 {
    width: auto;
    flex: auto;
  }

  .box_info .mr_32 {
    margin-right: 7px;
  }

  .box_info .f_20 {
    font-size: 17px;
  }

  .box_info .f_16 {
    font-size: 14px;
  }

  .coin_box {
    width: 74px;
    height: 37px;
    font-size: 13px;
    margin: 0 auto;
  }

  .Amount_box {
    width: 47px;
    height: 37px;
    font-size: 14px;
  }

  .Order_details {
    justify-content: space-between !important;
  }

  .mr_32.w-56.mb_r {
    margin-right: 0 !important;
  }
  .d-block-mv {
    display: block !important;
  }
}

@media (max-width: 480px) {
  .mobile_menu_wrap {
    width: 400px;
    padding: 21px 13px;
    bottom: 16px;
  }
}

@media (max-width: 376px) {
  .mobile_menu_wrap {
    width: 344px;
  }

  .box_info .w-256 {
    width: 149px;
    flex: 1 0 144px;
  }

  .Product_info_img {
    width: 31px;
    height: 31px;
    margin-right: 6px;
  }

  .box_info .f_20 {
    font-size: 15px;
  }

  .box_info {
    border-radius: 8px;
    border: 2px solid #006088;
    padding: 13px;
  }
}
