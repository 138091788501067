@font-face {
  font-family: "Aneba Neue";
  src: url("../fonts/AnebaNeue-Bold.eot");
  src: local("../fonts/Aneba Neue Bold"), local("AnebaNeue-Bold"),
    url("../fonts/AnebaNeue-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AnebaNeue-Bold.woff2") format("woff2"),
    url("../fonts/AnebaNeue-Bold.woff") format("woff"),
    url("../fonts/AnebaNeue-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Aneba Neue";
  src: url("../fonts/AnebaNeue-Medium.eot");
  src: local("../fonts/Aneba Neue Medium"), local("AnebaNeue-Medium"),
    url("../fonts/AnebaNeue-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AnebaNeue-Medium.woff2") format("woff2"),
    url("../fonts/AnebaNeue-Medium.woff") format("woff"),
    url("../fonts/AnebaNeue-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Aneba Neue";
  src: url("../fonts/AnebaNeue-Regular.eot");
  src: local("../fonts/Aneba Neue"), local("AnebaNeue-Regular"),
    url("../fonts/AnebaNeue-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AnebaNeue-Regular.woff2") format("woff2"),
    url("../fonts/AnebaNeue-Regular.woff") format("woff"),
    url("../fonts/AnebaNeue-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Aneba Neue";
  src: url("../fonts/AnebaNeue-SemiBold.eot");
  src: local("../fonts/Aneba Neue SemiBold"), local("AnebaNeue-SemiBold"),
    url("../fonts/AnebaNeue-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AnebaNeue-SemiBold.woff2") format("woff2"),
    url("../fonts/AnebaNeue-SemiBold.woff") format("woff"),
    url("../fonts/AnebaNeue-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Aneba Neue";
  src: url("../fonts/AnebaNeue-UltraLight.eot");
  src: local("../fonts/Aneba Neue UltraLight"), local("AnebaNeue-UltraLight"),
    url("../fonts/AnebaNeue-UltraLight.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AnebaNeue-UltraLight.woff2") format("woff2"),
    url("../fonts/AnebaNeue-UltraLight.woff") format("woff"),
    url("../fonts/AnebaNeue-UltraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

/* font-family */
.fw-200 {
  font-weight: 200;
  font-style: normal;
  font-family: "Aneba Neue";
}

.fw-600 {
  font-weight: 600;
  font-style: normal;
  font-family: "Aneba Neue";
}

.fw-500 {
  font-weight: 500 !important;
  font-style: normal;
  font-family: "Aneba Neue";
}

.fw-n {
  font-style: normal;
  font-family: "Aneba Neue";
}

.fw-bold {
  font-style: bold;
  font-family: "Aneba Neue";
}

.btn {
  font-weight: bold !important;
  font-weight: 600 !important;
}

* {
  margin: 0;
  padding: 0;
}

/*theme-btn start*/
.themebtn-blue {
  width: 112px;
  height: 64px;
  border-radius: 8px;
  background: radial-gradient(
    100% 100% at 50% 0%,
    #ffd951 6.77%,
    #cb4848 99.48%
  );
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  border: none;
  border-radius: 8px;
  text-align: center;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-style: normal;
  color: #570f0b !important;
  text-align: center;
  font-size: 24px;
  line-height: 140.8%;
}
.themebtn-blue-wh {
  width: 112px;
  height: 64px;
  border-radius: 8px;
  /* background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #fff6e0 0%,
    #8eef9e 14.58%,
    #006088 100%
  );
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  color: #011343 !important; */
  background: radial-gradient(
    100% 100% at 50% 0%,
    #ffd951 6.77%,
    #cb4848 99.48%
  );
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  color: #570f0b !important;
  border: none;
  border-radius: 8px;
  text-align: center;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  font-size: 24px;
  line-height: 140.8%;
}
.themebtn-blue-opac {
  opacity: 0.6;
}
.themebtn-blue-opac:hover {
  background: radial-gradient(
    100% 100% at 50% 0%,
    #ffd951 6.77%,
    #cb4848 99.48%
  );
}
.themebtn-dark {
  height: 64px;
  text-align: center;
  color: #fff !important;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
  line-height: 140.8%;
  border-radius: 8px;
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #b6acbb 0%,
    #4c444e 41.67%,
    #252231 100%
  );
  box-shadow: 0px 15px 13px 1px rgba(0, 0, 0, 0.32) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
}

.themebtn-blue:hover {
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #b6acbb 0%,
    #4c444e 41.67%,
    #252231 100%
  );
  box-shadow: 0px 15px 13px 1px rgba(0, 0, 0, 0.32) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: white !important;
}

.themebtn-dark:hover {
  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb4848 99.48%);
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  box-shadow: none;
  border: none;
  color: #570f0b !important;
}

/*theme-btn start end*/
body,
html {
  font-family: "Aneba Neue";
  font-size: 17px;
  min-height: 100%;
  height: auto;
  /* overflow-x: hidden; */
  background-color: black !important;
}

.support_btn.d-flex.align-items-center.mb_none_winngng {
  display: none !important;
}

ul li {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  width: 100%;
}

ul {
  padding: 0 !important;
  margin: 0 !important;
}

.lit_gray {
  color: #cbd8e7 !important;
}

.lit {
  color: #aaa !important;
}

/* theme-container */
.theme_container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* margin_class */
.mb_16 {
  margin-bottom: 16px;
}

.mr_8 {
  margin-right: 8px;
}

.mr_16 {
  margin-right: 16px;
}

.mt32 {
  margin-top: 32px !important;
}

.mb_32 {
  margin-bottom: 32px !important;
}

.mr_40 {
  margin-right: 40px !important;
}
.cus_mr_20 {
  margin-right: 20px !important;
}
.pt_70 {
  padding-top: 70px !important;
}

.mt_109 {
  margin-top: 109px !important;
}

.mt_32 {
  margin-top: 32px !important;
}

.pb_64 {
  padding-bottom: 64px;
}

.mt_16 {
  margin-top: 16px !important;
}

/* font-weight */
.fw-600 {
  font-weight: 600 !important;
}

/* all site-heading start*/

.w-400 {
  max-width: 400px;
}

.f_48 {
  font-weight: bold;
  font-family: "Aneba Neue";
  font-style: normal;
  font-size: 48px;
  line-height: 105.8%;
  color: #ffffff;
}

.f_24 {
  font-weight: 600;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 24px;
  line-height: 119.3%;
}

.f_16 {
  font-weight: 500;
  font-size: 16px;
  line-height: 120.6%;
}

.f_20 {
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 20px;
  line-height: 119.3%;
  color: var(--litgray);
}

.f_32 {
  font-style: normal;
  font-weight: 500;
  font-family: "Aneba Neue";
  font-size: 32px;
  line-height: 132.3%;
  color: var(--yellow);
}

.f_36 {
  font-weight: 600;
  font-size: 36px;
  line-height: 120.6%;
  color: #ffffff;
}

.f_64 {
  font-style: normal;
  font-weight: bold;
  font-family: "Aneba Neue";
  font-size: 64px;
  line-height: 105.8%;
  color: #ffffff;
}

.f_14 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 118.3%;
}

.f_12 {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  margin-bottom: 8px;
  padding-left: 11px;
}

/* header */
.nav_list ul li a {
  text-decoration: none;
}


.nav_list ul .nav-link {
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  line-height: 112.3%;
  text-align: center;
  color: #ffffff;
  margin-right: 16px;
  border-bottom: 1px solid transparent;
  position: relative;
}

.nav_list ul .nav-item.active .nav-link:after {
  bottom: -10px;
  opacity: 1;
}

.nav_list ul .nav-item.active .nav-link {
  color: #ff0044 !important;
}

.nav_list ul .nav-item:hover .nav-link {
  color: #ffd951 !important;
}

.nav_list ul .nav-item:hover .nav-link:after {
  bottom: -10px;
  opacity: 1;
}

.nav_list ul .nav-link:after {
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #ffd951;
  opacity: 0;
  -webkit-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}


/* .nav_list ul li a:hover {
  color: #006088 !important;
} */

.nav_logo img {
  width: 70px;
  height: 90px;
  cursor: pointer;
}

.header_inner {
  align-items: center;
  display: grid;
  grid-template-columns: 33% 33% 33%;
}

.circal_box {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2e363c;
  margin-right: 8px;
  position: relative;
}

.circal_box img {
  width: 27px;
  object-fit: cover;
}

.logout_wrap {
  display: flex;
  align-items: center;
  justify-content: end;
}

.circal_box .number {
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  border-radius: 24px;
  background: #f04;
  color: #fff;
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  line-height: 138.8%;
  position: absolute;
  top: -9px;
  right: -12px;
}

.user_profile_btn {
  /* margin-left: 16px; */
  position: relative;
}

.user_profile_btn img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.user_profile_wrap {
  position: relative;
}
.user_profile_wrap button::after {
  display: none !important;
}

.user_profile_btn {
  background: none !important;
  border: none;
  border: none !important;
}

.user_profile_wrap .btn_logout img {
  width: 24px;
  margin-right: 8px;
}

.user_profile_wrap .btn_logout {
  border-radius: 8px;
  background: #2e363c;
  border: 2px solid #373f46;
  width: 136px;
  height: 56px;
  color: #cbd8e7;
  font-size: 20px;
  font-weight: 600;
  line-height: 127.098%;
  position: relative;
  margin-top: 5px;
  z-index: 99;
}

.user_profile_wrap .btn_logout::after {
  content: "";
  /* background: url('../images/btn_after.png'); */
  width: 16px;
  height: 8px;
  position: absolute;
  right: 9px;
  top: -8px;
}

.drop_down {
  position: absolute;
  right: 0;
  bottom: -130px;
}
.dropdown-menu.show .dropdown-item {
  display: flex;
  align-items: center;
}
.dropdown-menu.show .dropdown-item:hover {
  background: #23292d;
  color: white;
}
.dropdown-menu.show {
  background: none;
  border: none;
  inset: 0px auto auto -80px !important;
}
.select-type-cus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 93%;
  padding: 18.5px 0px;
  margin: 0px auto;
}

.select-cus-input {
  width: 100%;
  background: rgba(19, 21, 29, 0.68);
  border: 2px solid #27242d;
  border-radius: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #cbd8e7;
  padding: 0px 0px;
  cursor: pointer;
  z-index: 99;
}
.no-border {
  border: none !important;
}
.options-dev {
  z-index: 99;
  width: 100%;
  left: 0;
}
.options-dev .cus_option {
  border-bottom: 2px solid #27242d;
  border-top: 2px solid #27242d;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #cbd8e7;
  padding: 18.5px 10px;
  cursor: pointer;
}
.options-dev .cus_option:hover {
  background: rgba(19, 21, 29, 0.68);
}
.select-cus-input img {
  height: 24px;
  width: 24px;
}
select option::before {
  content: "\00a0"; /* non-breaking space */
  display: block;
  height: 10px; /* Adjust the height as needed */
}
/* filter button single product screen*/
.drop_down_filter_togg {
  background-color: #004663 !important;
  border: none !important;
  padding: 10px 27px !important;
}
.drop_down_filter_togg:hover {
  background-color: #0e0528 !important;
}
.pageTittle_h1 .drop_down_filter_menu {
  background-color: #004663 !important;
  background-color: whitesmoke !important;

  inset: 0px auto auto 0px !important;
}
.drop_down_filter_menu .dropdown-item {
  color: #01134d;
  text-align: center;
  font-weight: 500;
  font-family: "AnebaNeue";
  font-size: 16px;
  padding: 10px 10px !important;
}
.drop_down_filter_menu .dropdown-item:hover {
  background-color: #0e0528 !important;
}

/* all site-heading end*/
/* mobile_menu_wrap */
.mobile_menu_wrap {
  position: fixed;
  width: 400px;
  min-height: 88px;
  background: #2e363c;
  border-radius: 16px;
  padding: 22px;
  z-index: 99;
  bottom: 16px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.BtnOpen span {
  font-weight: 500;
  font-style: normal;
  font-size: 32px;
  line-height: 140.8%;
  text-align: center;
  color: #ffffff;
  padding-left: 10px;
}

button.BtnOpen img,
.BtnClose img {
  width: 24px;
}

.menu_wrap_inner ul li {
  margin-right: 0;
}

button.BtnOpen,
.BtnClose {
  align-items: center;
  border: none;
  background: no-repeat;
  width: 100%;
  justify-content: center;
}

.menu_list a {
  text-decoration: none;
  margin-bottom: 13px;
  text-align: center;
}

.mobile_menu_wrap ul.footer-list {
  text-align: center;
  margin-bottom: 14px;
  padding-top: 9px;
}

.mobile_menu_wrap ul.footer-list {
  padding-top: 18px !important;
}

.mobile_menu_wrap {
  display: none;
}

.mobile_menu_wrap ul.footer-list {
  flex-direction: column;
}

.BtnOpen img {
  position: relative;
}

.mobile_menu_wrap ul.footer-list li {
  margin-bottom: 13px;
}

.menu_wrap_inner {
  display: none;
}

/* add_Card page css */
.payments-buttons {
  width: 100%;
  /* height: auto; */
  margin-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
}
.payments-buttons button {
  border-radius: 16px;
  border: 2px solid #27242d;
  background: rgba(19, 21, 29, 0.6);
  color: #d2dedd;
  padding: 16px 16px !important;
  height: 65px;
}
.payments-buttons button.active {
  border: 2px solid #e9a14b;
}
.card_info:hover {
  border: 2px solid #e9a14b;
  cursor: pointer;
}
.form-cvv-cd-card {
  max-width: 400px;
}
.form-cvv-cd-card .form-cvv-label {
  padding: 0px 0px 0px 4px !important;
}

.card_info {
  border-radius: 12px;
  border: 2px solid #27242d;
  background: rgba(19, 21, 29, 0.68);
  padding: 16px;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.card_info.active {
  border: 2px solid #e9a14b;
  cursor: pointer;
}

.card_info .card_left {
  display: flex;
  align-items: center;
}

.card_info .card_left .cardImg {
  width: 48px;
  height: 31px;
  margin-right: 16px;
}
.card_icon button {
  background-color: transparent;
  width: 24px;
}
.card_icon img {
  width: 24px;
}

.add_Card {
  background: url("../../../images/dark-updated-bg.png");
  min-height: 100vh;
  background-size: cover;
}

.add_card_inner {
  display: flex;
  justify-content: space-between;
}
.fc-cus-cvv {
  padding: 2.12rem 0.75rem !important;
}
.input_wrap .form-control {
  height: 56px;
  background: rgba(19, 21, 29, 0.68);
  border: 2px solid #27242d;
  border-radius: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #cbd8e7;
}
.input_wrap .input-border {
  border: 2px solid #e9a14b !important;
}

.input_wrap .form-control:focus {
  color: #cbd8e7;
  background: rgba(19, 21, 29, 0.68);
  border: 2px solid #e9a14b;
  outline: 0;
  box-shadow: none;
}

.add_form {
  max-width: 584px;
}

.input_wrap {
  position: relative;
}

.img_icon_wrap {
  position: relative;
}

.img_icon_wrap .img_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  width: 48px;
  height: 31px;
}

.custom_padding {
  [class*="col-"] {
    padding-left: 13px !important;
    padding-right: 0 !important;
  }
}

.w-400 {
  width: 100%;
}

.w-176 {
  max-width: 176px;
  flex: 1 0 176px;
  width: 100%;
}

.custom_padding {
  max-width: 584px;
}

.txt-blue {
  color: #e9a14b;
}
.acc_select_desk {
  display: none;
}
/* Order_Details */
.Order_Details_Wrap {
  max-width: 568px;
  margin: auto;
}

.Order_details {
  display: flex;
  justify-content: space-between;
}

.w-256 {
  width: 256px;
  flex: 1 0 256px;
}

.w-56 {
  width: 56px;
  flex: 1 0 56px;
}

.w-80 {
  width: 80px;
  flex: 1 0 80px;
}

.mr_32 {
  margin-right: 32px;
}

.box_info .w-256 {
  width: 231px;
  flex: 1 0 231px;
}

.box_info {
  border-radius: 8px;
  border: 2px solid #e9a14b;
  padding: 16px;
}

.coin_box img {
  width: 20px;
  margin-right: 2px;
}

.coin_box {
  border-radius: 8px;
  border: 2px solid #2e363c;
  background: #2e363c;
  width: 80px;
  height: 40px;
  color: #cbd8e7;
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  line-height: 193.8%;
}

.Product_info_img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 16px;
}

.Product_info {
  display: flex;
  align-items: center;
}

.box_info {
  margin-bottom: 16px;
}

.Amount_box {
  border-radius: 8px;
  /* border: 2px solid #006088; */
  border: 2px solid #e9a14b;
  width: 56px;
  height: 40px;
  color: #cbd8e7;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 175.598%;
  margin-left: auto;
}

.Order_details {
  display: flex;
  align-items: center;
}

.sub_total,
.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price_footer {
  margin-top: 232px;
}

a.edit_text {
  text-decoration: none !important;
}

.edit_text h5 {
  margin-bottom: 0;
}

.edit_text h5 img {
  width: 24px;
  position: relative;
  top: -17px;
}

a.edit_text {
  border-bottom: none !important;
  text-decoration: none !important;
}

.add_new_card {
  display: none;
}

/* add_Card page css end */

/* footer */
.footer_second {
  display: none;
}

.mb_block {
  display: none;
}

.desktop_logo {
  width: 56px;
  height: 73px;
}

.footer-wrap {
  background: black !important;
  padding: 114px 0 104px;
}

.footer-list li {
  margin-right: 32px;
}

.mr_32.w-56.mb_r {
  margin-right: 0 !important;
}

.footer-list li a {
  font-weight: 600;
  text-decoration: none;
  margin-bottom: 16px;
  color: #fff;
  font-size: 20px;
  line-height: 117.6%;
}

.footer-list li a:hover {
  color: #ffd951;
}

.footer-bottom {
  margin-top: 132px;
}

.footer-wrap .btnsupport {
  width: 220px;
  height: 56px;
  background: #2e363c;
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 20px;
  line-height: 133.3%;
  text-align: center;
  color: #ffffff;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-wrap .btnsupport img {
  width: 25px;
  margin-right: 7px;
}

.footer-wrap .btnsupport:hover {
  background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #fff6e0 0%,
    #8eef9e 14.58%,
    #006088 100%
  );
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  box-shadow: none;
  border: none;
  color: black !important;
}

.menu_wrap_inner {
  position: relative;
}

.menu_wrap_inner .logout_wrap {
  position: absolute;
  top: -69px;
  right: 0;
}

/* scrollbar */
.scroll_bar::-webkit-scrollbar {
  width: 8px !important;
}

.scroll_bar::-webkit-scrollbar-track {
  background: #1e192d;
  border-radius: 10px;
}

.scroll_bar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #3e344e;
}

/* placeholder */
::-webkit-input-placeholder {
  /* Edge */
  /* color: #CBD8E7 !important; */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  /* color: #CBD8E7 !important; */
}

::placeholder {
  /* color: #CBD8E7 !important; */
}

/* redio_wrap  . */
.redio_wrap {
  display: flex;
  align-items: center;
}
.redio_wrap input[type="checkbox"],
.redio_wrap input[type="radio"] {
  appearance: none;
}
.redio_wrap input[type="checkbox"] {
  outline: 0.1em solid #aaa;
}

.redio_wrap input[type="checkbox"] + label {
  cursor: pointer;
  position: relative;
  padding-left: 10px;
  line-height: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 158.5%;
  color: #aaa;
  font-weight: 500;
}

/* .redio_wrap input[type="checkbox"] + label::before,
.redio_wrap input[type="radio"] + label::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  aspect-ratio: 1;
  position: absolute;
  border-radius: 4px;
  border: 2px solid #aaa;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  opacity: 1;
  transition: all 0.3s;
} */

/* .redio_wrap input[type="checkbox"] + label::after,
.redio_wrap input[type="radio"] + label::after {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  aspect-ratio: 1;
  border-radius: 2px;
  background: #e9a14b;
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.3s;
} */

.redio_wrap input[type="checkbox"]:checked + label::after,
.redio_wrap input[type="radio"]:checked + label::after {
  opacity: 1;
}
