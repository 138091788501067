@font-face {
  font-family: "Aneba Neue";
  src: url("../fonts/AnebaNeue-Bold.eot");
  src: local("../fonts/Aneba Neue Bold"), local("AnebaNeue-Bold"),
    url("../fonts/AnebaNeue-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AnebaNeue-Bold.woff2") format("woff2"),
    url("../fonts/AnebaNeue-Bold.woff") format("woff"),
    url("../fonts/AnebaNeue-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Aneba Neue";
  src: url("../fonts/AnebaNeue-Medium.eot");
  src: local("../fonts/Aneba Neue Medium"), local("AnebaNeue-Medium"),
    url("../fonts/AnebaNeue-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AnebaNeue-Medium.woff2") format("woff2"),
    url("../fonts/AnebaNeue-Medium.woff") format("woff"),
    url("../fonts/AnebaNeue-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Aneba Neue";
  src: url("../fonts/AnebaNeue-Regular.eot");
  src: local("../fonts/Aneba Neue"), local("AnebaNeue-Regular"),
    url("../fonts/AnebaNeue-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AnebaNeue-Regular.woff2") format("woff2"),
    url("../fonts/AnebaNeue-Regular.woff") format("woff"),
    url("../fonts/AnebaNeue-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Aneba Neue";
  src: url("../fonts/AnebaNeue-SemiBold.eot");
  src: local("../fonts/Aneba Neue SemiBold"), local("AnebaNeue-SemiBold"),
    url("../fonts/AnebaNeue-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AnebaNeue-SemiBold.woff2") format("woff2"),
    url("../fonts/AnebaNeue-SemiBold.woff") format("woff"),
    url("../fonts/AnebaNeue-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Aneba Neue";
  src: url("../fonts/AnebaNeue-UltraLight.eot");
  src: local("../fonts/Aneba Neue UltraLight"), local("AnebaNeue-UltraLight"),
    url("../fonts/AnebaNeue-UltraLight.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AnebaNeue-UltraLight.woff2") format("woff2"),
    url("../fonts/AnebaNeue-UltraLight.woff") format("woff"),
    url("../fonts/AnebaNeue-UltraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Whyte";
  src: url("../fonts/Whyte-Bold.eot");
  src: local("../fonts/Whyte Bold"), local("Whyte-Bold"),
    url("../fonts/Whyte-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Whyte-Bold.woff2") format("woff2"),
    url("../fonts/Whyte-Bold.woff") format("woff"),
    url("../fonts/Whyte-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Whyte";
  src: url("../fonts/Whyte-Black.eot");
  src: local("../fonts/Whyte Black"), local("Whyte-Black"),
    url("../fonts/Whyte-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Whyte-Black.woff2") format("woff2"),
    url("../fonts/Whyte-Black.woff") format("woff"),
    url("../fonts/Whyte-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Alliance No.1";
  src: url("../fonts/AllianceNo1-Light.eot");
  src: local("../fonts/Alliance No.1 Light"), local("AllianceNo.1-Light"),
    url("../fonts/AllianceNo1-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AllianceNo1-Light.woff2") format("woff2"),
    url("../fonts/AllianceNo1-Light.woff") format("woff"),
    url("../fonts/AllianceNo1-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Alliance No.1";
  src: url("../fonts/AllianceNo1-Regular.eot");
  src: local("../fonts/Alliance No.1 Regular"), local("AllianceNo.1-Regular"),
    url("../fonts/AllianceNo1-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AllianceNo1-Regular.woff2") format("woff2"),
    url("../fonts/AllianceNo1-Regular.woff") format("woff"),
    url("../fonts/AllianceNo1-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Alliance No.1";
  src: url("../fonts/AllianceNo1-Bold.eot");
  src: local("../fonts/Alliance No.1 Bold"), local("AllianceNo.1-Bold"),
    url("../fonts/AllianceNo1-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AllianceNo1-Bold.woff2") format("woff2"),
    url("../fonts/AllianceNo1-Bold.woff") format("woff"),
    url("../fonts/AllianceNo1-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

:root {
  --litgray: #cbd8e7;
  --yellow: #e9a14b;
  --white: #fff;
  --darkblue: #160e24;
  --blue: #525197;
}

.blu {
  color: var(--blue) !important;
}

.clr-blue {
  color: var(--litgray) !important;
}

.clr-yellow {
  color: var(--yellow) !important;
}

.bgdark {
  background: linear-gradient(90deg, #0e0527 0.71%, #0e0724 100%);
}

/* font-family */
.fw-200 {
  font-weight: 200;
  font-style: normal;
  font-family: "Aneba Neue";
}

.fw-600 {
  font-weight: 600;
  font-style: normal;
  font-family: "Aneba Neue";
}

.fw-500 {
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
}

.fw-n {
  font-style: normal;
  font-family: "Aneba Neue";
}

.fw-bold {
  font-style: bold;
  font-family: "Aneba Neue";
}

.btn {
  font-weight: bold !important;
  font-weight: 600 !important;
}
/* border-radius */
.br-16 {
  border-radius: 16px;
}

* {
  margin: 0;
  padding: 0;
}

/*theme-btn start*/
.themebtn-red {
  width: 112px;
  height: 40px;
  background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #efd183 0%,
    #ff0044 100%
  );
  border-radius: 8px;
  border: none;
  border-radius: 8px;
  font-family: "Aneba Neue";
  font-size: 16px;
  line-height: 161.8%;
  text-align: center;
  color: #000000 !important;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-style: normal;
}

.themebtn-dark {
  width: 112px;
  height: 40px;
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #8a7f94 0%,
    #4c444e 41.67%,
    #241e35 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 15px 13px 1px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  font-family: "Aneba Neue";
  font-size: 16px;
  line-height: 161.8%;
  text-align: center;
  color: #fff !important;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-style: normal;
}

.themebtn-red:hover {
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #8a7f94 0%,
    #4c444e 41.67%,
    #241e35 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 15px 13px 1px rgba(0, 0, 0, 0.32);
  color: white !important;
}

.themebtn-dark:hover {
  background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #efd183 0%,
    #ff0044 100%
  );
  box-shadow: none;
  border: none;
  color: #570f0b !important;
}

/*theme-btn start end*/
body,
html {
  font-family: "Aneba Neue";
  font-size: 17px;
  min-height: 100%;
  height: auto;
  background: black !important;
  /* overflow-x: hidden; */
  /* background-color: var(--darkblue) !important; */
}

.support_btn.d-flex.align-items-center.mb_none_winngng {
  display: none !important;
}

ul li {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  width: 100%;
}

ul {
  padding: 0 !important;
  margin: 0 !important;
}

/* theme-container */
.theme-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* margin class */
.mb-8 {
  margin-bottom: 8px;
}

.mt-8 {
  margin-top: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.ml-16 {
  margin-left: 16px;
}

.mr-27 {
  margin-right: 27px;
}

.mt-24 {
  margin-top: 24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mt-40 {
  margin-top: 40px;
}

.shadow {
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
}
/* all site-heading start*/
.f48 {
  font-weight: bold;
  font-family: "Aneba Neue";
  font-style: normal;
  font-size: 48px;
  line-height: 105.8%;
  color: #ffffff;
}

.f24 {
  font-weight: 600;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 24px;
  line-height: 119.3%;
  color: var(--litgray);
}

.f16 {
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 16px;
  line-height: 119.3%;
  color: #ffffff;
}

.f20 {
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 20px;
  line-height: 119.3%;
  color: var(--litgray);
}

.f32 {
  font-style: normal;
  font-weight: 500;
  font-family: "Aneba Neue";
  font-size: 32px;
  line-height: 132.3%;
  color: var(--yellow);
}

.f36 {
  font-weight: 600;
  font-size: 36px;
  line-height: 120.6%;
  color: #ffffff;
}

.f64 {
  font-style: normal;
  font-weight: bold;
  font-family: "Aneba Neue";
  font-size: 64px;
  line-height: 105.8%;
  color: #ffffff;
}
.f14 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 118.3%;
  color: #cbd8e7;
}
/* all site-heading end*/

.fixed-top {
  max-width: 1200px;
  margin: 0 auto;
}

/* .header start css */


.header-wrap {
  position: relative;
  top: 32px;
  /* width: 100%; */
  z-index: 99;
}


.header-wrap nav {
  padding: 8px 114px 8px 32px;
}

.header-wrap nav ul .nav-link {
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  line-height: 112.3%;
  text-align: center;
  color: #ffffff;
  margin-right: 16px;
  border-bottom: 1px solid transparent;
  position: relative;
}

.header-wrap nav ul .nav-item.active .nav-link:after {
  bottom: 0;
  opacity: 1;
}

.header-wrap nav ul .nav-item.active .nav-link {
  color: #ff0044 !important;
}

.header-wrap nav ul .nav-item:hover .nav-link {
  color: #ff0044 !important;
}

.header-wrap nav ul .nav-item:hover .nav-link:after {
  bottom: 0;
  opacity: 1;
}

.header-wrap nav ul .nav-link:after {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #ff0044;
  opacity: 0;
  -webkit-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

.logo-white {
  display: none;
}
/* mobile_menu_wrap */
.mobile_menu_wrap {
  position: fixed;
  width: 400px;
  min-height: 88px;
  background: #221d33;
  border-radius: 16px;
  padding: 22px;
  z-index: 99;
  bottom: 36px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.BtnOpen span {
  font-weight: 500;
  font-style: normal;
  font-size: 32px;
  line-height: 140.8%;
  text-align: center;
  color: #ffffff;
  padding-left: 10px;
}

button.BtnOpen img,
.BtnClose img {
  width: 24px;
}

button.BtnOpen,
.BtnClose {
  display: flex;
  align-items: center;
  border: none;
  background: no-repeat;
  width: 100%;
  justify-content: center;
}

.menu_list a {
  text-decoration: none;
  margin-bottom: 13px;
  text-align: center;
}

.mobile_menu_wrap ul.footer-list {
  text-align: center;
  margin-bottom: 14px;
  padding-top: 9px;
}

.mobile_menu_wrap ul.footer-list {
  padding-top: 18px !important;
}

.BtnOpen img {
  position: relative;
  top: -7px;
}

.mobile_menu_wrap {
  display: none;
}

/* mobile_menu_wrap seconnd */
.second_menu {
  position: relative;
}

.second_menu .list_nav {
  position: absolute;
  top: -66px;
  z-index: 1;
  right: 0px;
}

.profile_header:after {
  content: "";
  position: absolute;
  width: calc(100% + 44px);
  height: 1px;
  background: #5f607a;
  left: -22px;
  bottom: -11px;
}

.profile_header .pricebox {
  background: #1a1525;
}

.profile_header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile_menu_wrap .user-profile {
  position: static;
  margin-top: 30px;
  width: 100%;
  background: no-repeat;
  padding: 0;
}

.circal {
  width: 56px;
  height: 56px;
  background: #1a1525;
  border-radius: 8px;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

span.circal img {
  margin-right: 0 !important;
}

.mobile_menu_wrap .user-profile {
  margin-bottom: 30px;
}

.mobile_menu_wrap .user-profile li a {
  position: relative;
  height: 56px;
  background: #1e192d;
  border-radius: 8px;
  padding-left: 16px;
  margin-bottom: 8px !important;
  font-weight: 600;
  font-size: 20px;
}

.mobile_menu_wrap .user-profile li a:hover img {
  filter: initial;
}

.mobile_menu_wrap .user-profile li a:hover {
  color: white;
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #b6acbb 0%,
    #4c444e 41.67%,
    #252231 100%
  );
}

/* search-wrap */
.search-form {
  display: inline-block;
  float: right;
  position: relative;
  width: 100%;
}

.search-wrap {
  display: flex;
  align-items: center;
  position: absolute;
  right: 32px;
  height: 100%;
  background: linear-gradient(90deg, #0e0527 0.71%, #0e0724 100%);
  border-left: 1px solid #4e4e63;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  padding-left: 32px;
  z-index: 9;
}

.search-wrap button img {
  width: 20px;
}

.search-wrap input {
  background: no-repeat;
  border: none;
  width: 351px;
  border-bottom: 1px solid #4e4e63;
  margin-right: 35px;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 159.3%;
  color: var(--litgray);
  outline: none;
  padding-bottom: 5px;
}

.search-wrap button {
  border: none;
  background: none;
}

/* header-wrap end css */

/* .banner-wrap start css */
.banner-wrap {
  background-image: url("../../../homeImages/homebackground.png");

  background-size: cover;
  background-position: center;
  position: relative;
  min-height: 680px;
}

.banner-wrap::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 78px;
  right: 0;
  bottom: 0;
  /* background: linear-gradient(180deg, rgba(22, 14, 36, 0) 0%, #0d121e 100%); */
  background: linear-gradient(180deg, rgba(10, 10, 10, 0) 0%, #0a0a0a 100%);
/* background: black; */
}

.banner-inner {
  background: url("../images/banner.png");
  background-size: cover;
  background-position: center;
  min-height: 496px;
  position: relative;
  margin-top: 60px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-w288 {
  width: 288px;
  height: 64px;
  font-size: 24px;
  line-height: 140.8%;
  font-weight: 600 !important;
  color: #000000 !important;
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
}

.banner-content button {
  margin-left: auto;
  margin-right: auto;
}

.banner-content p {
  max-width: 576px;
  margin: 0 auto;
  font-weight: 500;
}

.banner-content h1.f48 {
  font-weight: 600;
}

.banner-content {
  text-align: center;
}

.mb-show {
  display: none;
}

/* .join-with-wrap */
.join-with-wrap .join-button button:hover {
  background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #efd183 0%,
    #ff0044 100%
  );
  color: #72140e;
}

.join-with-wrap {
  display: none;
}

.join-with-wrap h1 {
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 16px;
  line-height: 105.8%;
  text-align: center;
  color: var(--white);
  margin-bottom: 24px;
  margin-top: 24px;
}

.join-with-wrap .join-button button {
  width: 120px;
  height: 48px;
  background: #160e24;
  opacity: 0.8;
  border-radius: 8px;
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 16px;
  line-height: 159.8%;
  text-align: center;
  color: var(--white);
  border: none;
  margin-right: 13px;
}

.join-with-wrap .join-button button {
  margin-top: 9px;
}

.join-with-wrap .join-button button:last-child {
  margin-right: 0;
}

.join-with-wrap .join-button button img {
  width: 20px;
}

.btn-join img {
  margin-right: 8px;
}
/* .banner-wrap css end */


.safe-betting-img img {
  border-radius: 16px;
}

.safe-betting-img {
  margin-bottom: 116px;
}

.Safe-Betting-right button {
  width: 168px;
  height: 56px;
  font-size: 20px;
  line-height: 138.8%;
  text-align: center;
}

.Safe-Betting-right h1 {
  max-width: 500px;
}
/* Safe Betting  end*/

/* Hundreds-games-wrap start */


.img-game {
  width: 224px;
  height: 224px;
  background: #1f2932;
  box-shadow: 0px 7px 11px rgba(0, 0, 0, 0.38);
  border: 3px solid #687f93;
  border-radius: 16px;
  padding: 3px;
  overflow: hidden;
  margin-right: 20px;
}

.game-card .img-game:hover {
  border: 3px solid #fff;
}

.img-game img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.game-card a {
  text-decoration: none;
}

.game-card:hover h6 {
  color: white;
}

.game-card {
  margin-bottom: 8px;
}

.game-card h6 {
  font-weight: 500;
  font-size: 12px;
  line-height: 144%;
  color: #0f1419;
  margin-top: 8px;
}

.games_wrap {
  position: relative;
}

.games-card-img {
  position: absolute;
  right: -170px;
  top: -30px;
}

.game-card {
  margin-bottom: 25px;
}

.mb_none_btn {
  display: none !important;
}

.support_btn.d-flex.align-items-center.mb_none_btn {
  display: none !important;
}

.game-wrap-row {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  width: 100%;
}
.game-wrap {
  position: relative;
  padding-bottom: 57.7%;
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
}

.game-wrap iframe {
  border-radius: 8px !important;
}
.game-outer {
  max-width: 1200px;
  margin: 0 auto;
}
/* Hundreds-games-wrap enf */
.mb_none_winngng {
  display: none;
}
.btn_iframe {
  padding: 15px 16px;
  background: #241d2f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 133.3%;
  color: #ffffff;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iframe_btn_wrap .btn_iframe:hover {
  background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #efd183 0%,
    #ff0044 100%
  );
  color: #241d2f;
}
.iframe_btn_wrap {
  margin-top: 30px;
}
.iframe_btn_wrap .btn_iframe img {
  margin-right: 7px;
  width: 24px;
}
/* Instant-deposits */
.Instant-deposits {
  background: var(--darkblue);
  min-height: 840px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Instant-deposits p {
  max-width: 728px;
  margin-left: auto;
  margin-right: auto;
}

.brand-logo {
  margin-top: 48px;
}

.skrillPayment {
  margin-right: 50px;
  width: 118px;
  height: 72px;
}

.netellerPayment {
  width: 173px;
  height: 72px;
}

/* Instant-deposits end*/

/* Always by Your Side start */
.support_btn {
  margin-top: 32px;
}

.support_btn button {
  width: 168px;
  height: 56px;
  font-size: 20px;
}

.box-red {
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
}

.box-black {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 15px 13px 1px rgba(0, 0, 0, 0.32);
}


.pb-200 {
  padding-bottom: 200px;
  padding-top: 75px;
}

.Start-Journey {
  margin-top: 139px;
}

.Start-Journey button {
  margin-left: auto;
  margin-right: auto;
}

/* faqs */
.faqs h1 {
  margin-bottom: 64px;
}

.faqs {
  background: var(--darkblue);
  padding: 104px 0 94px;
  overflow: hidden;
  z-index: 9;
  position: relative;
}

.faqs p a {
  color: var(--yellow);
  border-bottom: 1px solid var(--yellow);
  text-decoration: none;
}

.faqs p a:hover {
  color: #ff0044;
  border-bottom: 1px solid #ff0044;
}

.faqs .support_btn {
  margin-top: 104px;
  justify-content: center;
}

.faqs h5 {
  color: white;
}

.faqs p {
  color: white;
}



.user-detail h6 {
  margin-bottom: 0;
  text-align: left;
}

.user-detail {
  margin-top: 16px;
  display: flex;
  align-items: self-start;
}

.img-client img {
  margin-left: 8px;
  width: 24px;
  border-radius: 50px;
}

.user-detail p {
  color: #8a939a;
  margin-bottom: 0;
}

.client-content {
  margin-left: 10px;
}

.user-detail a {
  display: flex;
  text-decoration: none;
}

.clinet_wrap_details_werp,
.clinet_wrap_inner {
  position: relative;
}

.clinet_wrap_inner:hover::after {
  display: none;
}

.clinet_wrap_inner::after {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, rgba(20, 31, 64, 0) 0%, #161027 100%);
  bottom: 0;
  height: 288px;
  width: 100%;
}

.clinet_wrap_details {
  max-width: 704px;
  margin-left: auto;
  position: absolute;
  top: 0;
  right: -134px;
}

.clint_box {
  position: relative;
  top: -16px;
}

.mt-140 {
  margin-top: 455px;
}

.clinet_wrap_inner .row > [class*="col-"] {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.start-your P {
  max-width: 728px;
  margin-left: auto;
  margin-right: auto;
}

/* about-page */
.about-banner {
  position: relative;
  top: 70px;
}

.about-banner {
  max-width: 768px;
  margin-left: auto;
}

.about-banner img {
  border-radius: 16px;
  min-height: 450px;
}

.AboutContent {
  /* background: black; */
  background: linear-gradient(180deg, rgba(10, 10, 10, 0) 0%, #0a0a0a 100%);
  padding-top: 88px;
  padding-bottom: 65px;
}

.about-content-wrap .social-link ul li a:hover {
  color: #ff0044;
}

.about-content-wrap .social-link ul li a {
  text-decoration: none;
  color: white;
}

.about-content-wrap .social-link {
  padding-top: 140px;
}

.about-content-wrap .social-link ul li {
  margin-bottom: 14px;
}

.about-content-wrap .social-link {
  max-width: 365px;
  padding-left: 80px;
}

.about-content-wrap {
  display: flex;
}

.about-content-wrap .about-content {
  margin-left: auto;
  /* max-width: 768px; */
}
.ul_privacy_pol li {
  list-style-type: disc;
  color: #fff;
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 20px;
  line-height: 119.3%;
  margin-bottom: 5px;
}
.about-content-wrap .about-content p {
  color: white;
}

.about-content-wrap .about-content .f36 {
  margin-top: 32px;
  margin-bottom: 32px !important;
}

/* Contact-us */
.ContactUs-wrap .ChangeAvatar {
  display: none;
}

.input_wrap textarea.form-control {
  min-height: 168px;
}

.mt-100 {
  margin-top: 100px;
}

.errorbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 56px;
  height: auto;
  padding: 5px;
  border-radius: 16px;
  font-weight: 500;
  font-size: 20px;
  line-height: 118.3%;
  margin-top: 30px;
}

.boxSucess {
  background: #51ff82;
  color: #175328;
}

.boxWrong {
  background: #ff0044;
  color: white;
}


.tabs_button ul {
  display: flex;
}

.btn_tabs {
  cursor: pointer;
  background: #160e24;
  border-radius: 16px;
  font-weight: 500;
  font-size: 20px;
  line-height: 118.3%;
  text-align: center;
  color: #cbd8e7;
  padding: 16px 28px;
  margin-right: 16px;
}

.btn_tabs:hover {
  background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #fff6e0 0%,
    #ffd66e 8.85%,
    #ff0044 100%
  );
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  color: #57100c !important;
}

.icon_wrap {
  position: relative;
}

.icon_wrap .icon {
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translateY(-50%);
}

.search_wrap .icon_wrap .form-control {
  width: 232px;
  height: 56px;
  background: #160e24;
  border-radius: 8px;
  border: none;
  padding-left: 45px;
  font-weight: 500;
  font-size: 20px;
  line-height: 118.3%;
  color: #687f93;
}

.Search_wrap {
  display: flex;
  justify-content: space-between;
  margin: 32px 0 0;
  padding-bottom: 18px;
}

/* edit_profile */
.main-wrap-form .themebtn-red:hover {
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #b6acbb 0%,
    #4c444e 41.67%,
    #252231 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 15px 13px 1px rgba(0, 0, 0, 0.32);
  color: white !important;
}

.main-wrap-form .themebtn-red {
  background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #fff6e0 0%,
    #ffd66e 8.85%,
    #ff0044 100%
  );
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  color: #57100c !important;
}

.main-wrap-form .themebtn-dark {
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #b6acbb 0%,
    #4c444e 41.67%,
    #252231 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 15px 13px 1px rgba(0, 0, 0, 0.32);
}

.main-wrap-form .themebtn-dark:hover {
  background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #fff6e0 0%,
    #ffd66e 8.85%,
    #ff0044 100%
  );
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  color: #57100c !important;
}

.main-wrap-form {
  margin-top: 24px;
}

.main-wrap-form button {
  width: 100%;
  height: 64px !important;
}

.main-wrap-form .support_btn {
  margin-top: 64px;
}

.fomBox {
  width: 400px;
  margin: 32px auto 0px;
  padding-bottom: 100px;
}

.input_wrap .form-control {
  background: #161126;
  border: 2px solid #343d49;
  box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  height: 64px !important;
  font-family: "Alliance No.1";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px !important;
  color: #cbd8e7;
}
/* .main-wrap-form .form-floating > .form-control:focus ~ label,  
.main-wrap-form .form-floating > .form-control-plaintext ~ label, 
.main-wrap-form .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.99) translateY(-0.3rem) translateX(0.15rem);
} */
.main-wrap-form .form-floating {
  margin-bottom: 8px;
}

.main-wrap-form .form-floating > label {
  font-family: "Alliance No.1";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  color: white;
  opacity: 1 !important;
}

.main-wrap-form .form-control:focus {
  color: #cbd8e7 !important;
}

.main-wrap-form .form-control:focus ~ label {
  font-family: "Alliance No.1";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 23px;
  opacity: 1 !important;
  color: #ffffff !important;
  box-shadow: none !important;
}

.form-control:focus {
  color: #212529;
  background-color: black !important;
  border-color: #343d49 !important;
  outline: 0;
  box-shadow: none !important;
}

.btnChangeAvatar {
  width: 136px;
  height: 40px;
  background: #160e24;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 155.3%;
  text-align: center;
  color: #ffffff !important;
}

.z-9 {
  position: relative;
  z-index: 9;
}

.btnChangeAvatar span {
  color: white;
}

.ChangeAvatar {
  display: flex;
  align-items: center;
}

.ChangeAvatar img {
  width: 40px;
  margin-right: 16px;
}

.right_sider {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.header-wrap.headerLobby nav {
  padding: 8px 32px 8px 32px !important;
}

.pricebox {
  width: 128px;
  height: 40px;
  background: #2a2336;
  border-radius: 8px;
  position: relative;
  display: grid;
  font-weight: 600;
  font-size: 20px;
  line-height: 129.8%;
  text-align: center;
  color: #cbd8e7;
  align-items: center;
  padding-left: 16px;
}

.pricebox img {
  position: absolute;
  top: -2px;
  left: -16px;
  width: 45px;
}

.iconNav {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2a2336;
}

.iconNav:hover {
  background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #efd183 0%,
    #ff0044 100%
  );
}

.iconNav img {
  width: 24px;
}

/* .btnGetCoins {
  width: 152px;
  height: 58px;
  background: url("../images/coin-btn.png");
  background-size: cover;
  border-radius: 16px;
  padding-bottom: 13px;
  font-family: "Whyte";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 176.02%;
  text-align: center;
  color: #fff5da;
  position: relative;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.43);
  z-index: 1;
} */

.user-profile ul {
  margin-top: 16px !important;
}

.user-profile ul li {
  margin-bottom: 10px;
}

.user-profile ul li:last-child {
  margin-bottom: 0;
}

.user-profile ul li a {
  font-weight: 500;
  font-size: 16px;
  line-height: 161.3%;
  color: #cbd8e7;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.user-profile ul li img {
  width: 24px;
  margin-right: 8px;
}

.user-profile ul li {
  margin-bottom: 8px;
}
/* Lobby-page-css */

/* .user-profile */
.profile-wrap {
  position: relative;
}

.user-name {
  display: flex;
  align-items: self-start;
}

.user-name h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 117.8%;
  color: #cbd8e7;
  margin-bottom: 0;
  width: 100%;
}

.user-content {
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
}

.user-content a {
  font-weight: 500;
  font-size: 12px;
  line-height: 179.8%;
  color: #e9a14b;
  text-decoration: none;
}

.user-content a:hover {
  color: #ff0044;
}

.user-profile ul li a:hover {
  color: #ff0044;
}

.user-profile ul li a:hover img {
  filter: invert(100%) sepia(97%) saturate(4801%) hue-rotate(338deg)
    brightness(98%) contrast(110%);
}

.user-profile {
  position: absolute;
  width: 192px;
  background: #0f0627;
  border-radius: 16px;
  padding: 24px;
  right: 111px;
  top: 0;
}

.user-name img {
  width: 32px;
}

.mr-8 {
  margin-right: 8px;
}

.btnMenu img {
  width: 24px;
}

.lobby_nav {
  display: flex;
  align-items: center;
}

.btnMenu {
  width: 88px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #0e0527 0.83%, #0e0724 100%);
  border-radius: 16px;
  border: none;
}



/* games-page */
.py-160 {
  padding-top: 160px;
  padding-bottom: 160px;
}


.Games-bg .theme-container {
  height: 100%;
  width: 100%;
}
.banner-wrap.Games-bg {
  min-height: 100vh !important;
}
.banner-wrap.Games-bg:after {
  display: none;
}

.item-wrap .game-card h6 {
  color: #687f93;
}

.item-wrap .img-game {
  position: relative;
}
.card_wrap_itme.vew_all {
  display: flex;
}
.card_wrap_itme.vew_all .wrap_item_card:last-child .img-game {
  margin-right: 0;
}
.card_wrap_itme.vew_all .img-game {
  max-width: 224px !important;
  height: 224px;
}
.card_wrap_itme.vew_all .img-game:last-child {
  margin-right: 0 !important;
}
.item-wrap .img_icon {
  position: absolute;
  top: 14px;
  right: 15px;
  z-index: 99;
}
.item-wrap .img_icon.liked .heartfill {
  display: block !important;
}
button.img_icon {
  outline: none;
}
.item-wrap .img_icon.disliked .heartfill {
  display: none;
}
.item-wrap .img_icon .heartfill {
  display: none !important;
}
.img_icon.liked .heart {
  display: none;
}
button.img_icon.like img.heartfill {
  display: block !important;
}
button.img_icon.like img.heart {
  display: none !important;
}
.img_icon {
  background: none;
}

/* .heartfill {
	display: none  !important;
}

.item-wrap .game-card:hover .heart {
	display: none;
}

.item-wrap .game-card:hover .heartfill {
	display: block  !important;
} */

.vew_all .img-game {
  width: auto !important;
}

.row.vew_all > * {
  padding-right: 0;
  padding-left: 0;
}

.row.vew_all .img-game {
  height: 198px;
  margin-right: 11px;
}






.claim_now {
  text-align: center;
  padding-top: 211px;
  padding-bottom: 91px;
}

.claim_now button {
  margin-top: 39px;
}

.claim_now p {
  max-width: 728px;
  margin-left: auto;
  margin-right: auto;
}

/* footer start-css */
/* .footer_second p {
  font-size: 16px;
  line-height: 119.8%;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0;
} */

.footer_second {
  background: #000;
  display: none;
  min-height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  padding: 0 22px;
}

.logo_footer {
  width: 45px;
  height: 60px;
  display: none;
}

.footer-wrap {
  /* background: black; */
  background: linear-gradient(180deg, rgba(10, 10, 10, 0) 0%, #0a0a0a 100%);
  padding: 114px 0 104px;
}
.footer-list {
  /* display: flex; */
}
.footer-list li a {
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 20px;
  line-height: 105.8%;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 25px;
  display: inline-flex;
}

/* .footer-list li a:hover {
  color: #ff0044;
} */

.footer-bottom {
  margin-top: 132px;
}

.footer-bottom p {
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 20px;
  line-height: 105.8%;
  color: #ffffff;
  margin-bottom: 0;
}

.footer-wrap .btnsupport {
  width: 192px;
  height: 56px;
  background: #241d2f;
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 20px;
  line-height: 133.3%;
  text-align: center;
  color: #ffffff;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-wrap .btnsupport img {
  width: 25px;
  margin-right: 7px;
}

.footer-wrap .btnsupport:hover {
  background: radial-gradient(
    100% 100% at 50% 0%,
    #ffd951 6.77%,
    #cb4848 99.48%
  ) !important;
  box-shadow: none;
  border: none;
  color: black !important;
}

/* placeholder */
::-webkit-input-placeholder {
  /* Edge */
  color: #4e4e63;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #4e4e63;
}

::placeholder {
  color: #4e4e63;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.card_wrap_itme.vew_all {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-gap: 4px;
}
.card_wrap_itme.vew_all .wrap_item_card {
  margin: 0 auto;
}
.card_wrap_itme.vew_all .img-game {
  width: auto !important;
  margin-left: auto;
  margin-right: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 2px solid #343d49 !important;
  -webkit-text-fill-color: #cbd8e7 !important;
  -webkit-box-shadow: 0 0 0px 1000px #161126 inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
/* setting-wrap-main */

.Tabs-wrap button img {
  width: 24px;
  margin-right: 8px;
}
.Tabs-wrap .nav-tabs {
  border-bottom: 0;
  margin-bottom: 64px !important;
}
.Tabs-wrap .nav-tabs .nav-link {
  padding: 16px 16px;
  height: 56px;
  background: #0e0627;
  border-radius: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 118.3%;
  color: #cbd8e7;
  margin-right: 16px;
  border: 2px solid transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Tabs-wrap .nav-tabs .nav-link.active {
  border: 2px solid #585871 !important;
  background: #0e0627;
  color: #cbd8e7;
}
.Tabs-wrap .nav-tabs .nav-link:hover {
  border: 2px solid #585871 !important;
}

/* switch style */
.switch input {
  display: none;
}
.switch {
  display: inline-block;
  width: 50px;
  height: 25px;
  transform: translateY(50%);
  position: relative;
}
/* Style Wired */
.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 24px;
  box-shadow: 0 0 0 4px #4e4e63, 0 0 4px #4e4e63;
  cursor: pointer;
  border: 0px solid transparent;
  overflow: hidden;
  transition: 0.4s;
  background-color: #160e24;
}
.slider:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #5f607a;
  border-radius: 30px;
  transform: translateX(-24px);
  transition: 0.4s;
}

input:checked + .slider:before {
  transform: translateX(24px);
  background: #f0c57f;
}
input:checked + .slider {
  box-shadow: 0 0 0 4px #4e4e63, 0 0 4px #4e4e63;
  background: #fa4459;
}

/* Style Flat */
.switch.flat .slider {
  box-shadow: none;
}
.switch.flat .slider:before {
  background: #fff;
}
.switch.flat input:checked + .slider:before {
  background: white;
}
.switch.flat input:checked + .slider {
  background: limeGreen;
}
/* switch style end */

/* .setting-wrap{
	padding-bottom: 63px;
} */
.switch_wrap_main .switch-content {
  max-width: 343px;
}
.switch_wrap_main .switch-content .f20 {
  font-weight: 600 !important;
}
.Notifications_switch_wrap {
  max-width: 440px;
  margin: 0 auto;
}
.switch_wrap_main {
  margin-bottom: 28px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

/* ReferFriend_content */
.ReferFriend_content {
  max-width: 568px;
}
.ReferFriend_content .f48 {
  font-weight: 600 !important;
}
.ReferFriend_content p,
.get_start_content p {
  color: #cbd8e7;
}

.get_start_content {
  max-width: 420px;
  position: relative;
  z-index: 10;
  min-height: 600px;
}
.mt-64 {
  margin-top: 64px;
}
.get_start_content .f20 {
  font-weight: 600;
}
.copy_Wrap {
  position: relative;
}
.copy_Wrap .btn-copy img {
  margin-right: 0;
}
.copy_Wrap .btn-copy {
  position: absolute;
  width: 56px;
  height: 60px !important;
  right: 2px;
  top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2a2336;
  border-radius: 0px 11px 11px 0px;
}
.copy_Wrap .btn-copy:hover {
  background: radial-gradient(
    77.34% 77.34% at 50% 22.66%,
    #efd183 0%,
    #ff0044 100%
  );
}
.theme-container.tabs-menu ul li {
  padding-bottom: 2px;
}
.get_start_wrap {
  position: relative;
  min-height: 500px;
}

.ReferFriend_man_img img {
  position: relative;
  z-index: -1;
}
.ReferFriend_man_img {
  position: absolute;
  max-width: 816px;
  bottom: -62px;
  right: -161px;
}
.get_start_wrap {
  position: relative;
}
.get_start_wrap::after {
  content: "";
  bottom: -64px;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(20, 17, 48, 0) 0%,
    rgba(20, 17, 50, 0.8) 100%
  );
  width: 10000px;
  height: 464px;
  z-index: 9;
  left: -100%;
}
.scrollable-container {
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: transparent transparent; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */
}

.scrollable-container::-webkit-scrollbar {
  width: 0.5em; /* Adjust the width as needed */
}

.scrollable-container::-webkit-scrollbar-track {
  background: transparent; /* Set the background color as needed */
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: transparent; /* Set the thumb color as needed */
}
.game-card:hover .img-game img {
  transition: all ease-in-out 0.5s;
}
.game-card:hover .img-game img {
  transform: scale(1.1);
}
